const dictionary: Record<string, string> = {
	'Customer information not found': '未找到客户信息',
	'Account subscription expired, renew to continue.': '帐户订阅已过期，请续订以继续。',
	'Account subscription cancelled, renew to continue.': '帐户订阅已取消，请续订以继续。',
	Warning: '警告',
	Yes: '是的',
	No: '不',
	Companies: '公司',
	'Corrupted file': '文件损坏',
	'Extracted data': '提取的数据',
	'Not processing': '未处理',
	Processing: '处理中',
	Processed: '已处理',
	'In queue': '排队中',
	'Under maintenance': '维护中',
	'Send video': '发送视频',
	Error: '错误',
	'Add files or drag': '添加文件或拖动',
	'Unsupported file': '不支持的文件',
	'Drop files here': '将文件拖放到此处',
	'Unsupported video format': '不支持的视频格式',
	and: '和',
	'is larger than': '大于',
	'Max size': '最大尺寸',
	Blur: '模糊',
	'Are you sure you want to exit? You have uploads in process.': '您确定要退出吗？您正在上传。',
	' or too many files': '或多个文件',
	'Files number exceeded': '超出文件数量',
	'Max files': '最大文件数',
	'User created successfully': '用户创建成功',
	'File duplicated': '文件重复',
	'Select the desired company below': '在下面选择对应的BU',
	'Select the desired industrial site below': '在下面选择对应的工厂',
	'Create a new company': '创建新BU',
	'Create a new industrial site': '创建新的工厂',
	'Enter a name for the company': '输入BU名称',
	'Enter a name for the industrial site': '输入工厂的名称',
	'Company created successfully': 'BU创建成功',
	'Industrial site created successfully': '工厂创建成功',
	'There are no registered companies': '暂无注册的BU',
	'There are no registered industrial sites': '尚无注册工厂',
	'Congratulations! Now create an industrial site that belongs to this company.': '恭喜！现在创建属于该BU的工厂。',
	'Create industrial site': '创建工厂',
	'Welcome to Kinebot, create your first company': '欢迎来到Kinebot，创建你的BU',
	'Create company': '创建BU',
	'Go back': '回去',
	Back: '后退',
	Create: '创造',
	'Ergonomic tools': '人体工程学工具',
	'Failed to get file custom report result': '无法获取文件自定义报告结果',
	'Select the most suitable tool for your analysis': '选择最适合您的分析工具',
	'Biomechanical analysis': '生物力学分析',
	'Load analysis': '载荷分析',
	'Repeatability analysis': '重复性分析',
	Workstation: '工作站点',
	Description: '描述',
	Size: '尺寸',
	'Upload date': '上传日期',
	Sector: '部门',
	Rating: '评分',
	Actions: '操作',
	Action: '行动',
	'Not assigned': '未分配',
	'Heads up!': '小心！',
	'Heads up! Want to delete this file?': '注意！要删除此文件吗？',
	'Force/Load movement data': '力/变化数据信息',
	Repetitions: '重复',
	'Repetitions per minute': '每分钟重复次数',
	Legs: '腿',
	Diagnostic: '诊断',
	'Analysis results': '分析结果',
	'Not found': '未找到',
	Light: '当活动较轻',
	'Failed to fetch data': '无法获取数据',
	'Average exposure of the workplace': '工作场所平均暴露量',
	'Unable to show results.': '无法显示结果。',
	'Load less than 2 kg (intermittent)': '负载小于2公斤（间歇）',
	'Load from 2 kg to 10 kg (intermittent)': '负载从2公斤到10公斤（间歇）',
	'Load from 2 kg to 10 kg (static or repetitive)': '负载从 2 公斤到 10 公斤（静态或重复）',
	'If more than 10 kg or repeated or rapid build up of force': '较大负载 10 千克重复负载或打击',
	'Repetitive load or rapid build up of force greater than 10 kg': '更大的负载：10 公斤的重复负载或打击',
	'Legs and feet supported and with equal load distribution': '腿部和脚部得到支撑，且负荷分布均匀',
	'Legs without support': '腿部无支撑',
	Reports: '报告',
	'Select the data visualization model': '选择数据可视化模型',
	Angle: '角度',
	'Angle in degrees (°)': '角度（以度为单位）',
	'Time in seconds (s)': '时间（秒）',
	Parameters: '参数',
	'Manual lifting': '手动提升',
	'NIOSH / ISO 11228-1 - Manual lifting analysis': 'NIOSH / ISO 11228-1 - 手动提升分析',
	'Push and pull': '推和拉',
	'KIM - Push and pull': 'KIM——推和拉',
	'KIM - PP Report Results': '评估和设计与手工推拉负载相关的关键指标方法结果报告 （KIM-PP）',
	meters: '米',
	'Temporal distribution': '时间分布',
	Distance: '距离',
	'Work condition': '工作条件',
	'Work conditions': '工作环境',
	'Working conditions': '工作环境',
	'Vehicle properties': '车辆特性',
	Vehicle: '车辆',
	'Distance or duration': '距离或持续时间',
	'Select one of the options below': '选择以下选项之一',
	'Short distances': '短距离',
	'Long distances': '长距离',
	Time: '时间',
	Mass: '大量的',
	Precision: '精确',
	'Worker gender': '工人性别',
	'Pushing / Pulling in pairs': '双手推/拉',
	'By distance': '按距离',
	'By duration': '按持续时间',
	'Distance per work day (in meters)': '每个工作日的距离（米）',
	'Duration per work day (in minutes)': '每个工作日的持续时间（分钟）',
	'Select one of the vehicles': '选择其中一辆车',
	Barrows: '手推车',
	Carriages: '车厢',
	'Overhead conveyors': '悬挂输送机',
	'Overhead cranes': '桥式起重机',
	'Choose an option': '选择一个选项',
	'Reference image': '参考图片',
	'Imagem de referÃªncia': '参考图片',
	'Driveway conditions': '车道状况',
	'Inclination or stairs': '斜坡或楼梯',
	'Select the mass to be transported (in kg)': '选择要运输的质量（以千克为单位）',
	'Driveway completely level, smooth, solid, dry, without inclinations': '车道完全平整、光滑、坚固、干燥、无倾斜',
	'Driveway mostly smooth and level, with small damaged spots/faults, without inclinations':
		'车道基本平坦，有小的损坏点/故障，无倾斜',
	'Mixture of cobbles, concrete, asphalt, slight inclinations, dropped kerb':
		'鹅卵石、混凝土、沥青、轻微倾斜、掉落的路缘石',
	'Mixture of roughly cobbled, hard sand, slight inclinations, small edges/sills':
		'粗糙的鹅卵石、坚硬的砂子、轻微倾斜、微小的台阶/门槛',
	'Earth or roughly cobbled driveway, potholes, heavy soiling, slight inclinations, landings, sills':
		'泥土或粗糙鹅卵石铺就的车道、坑洼、重度污垢、轻微倾斜、平台、门槛',
	'Inclinations of 2 ≤ 4° (4 ≤ 8%)': '倾角 2 至 4° (4 至 8%)',
	'Inclinations of 5 ≤ 10° (9 ≤ 18%)': '倾角 5 至 10° (9 至 18%)',
	'Stairs, inclinations > 10° (18%)': '楼梯，倾斜度>10°（18%）',
	'No significant inclination': '无明显倾向',
	'Select the appropriate options': '选择适当的选项',
	'Unfavorable working conditions - more than one can be selected': '不利的工作条件 - 可选择多个',
	'Unfavorable vehicle properties - more than one can be selected': '不利的车辆性能 - 可选择多项',
	'Unfavorable working conditions': '不利的工作条件',
	'Unfavorable vehicle properties': '车辆性能不佳',
	'Regularly significantly increased starting forces, because transport devices sink into the ground or get wedged':
		'由于运输设备沉入地面或被卡住，因此启动力通常会显著增加',
	'Frequent stops with braking': '频繁在制动下停止',
	'Frequent stops without braking': '频繁在无制动下停止',
	'Many changes of direction or curves, frequent maneuvering': '多次改变方向或转弯，频繁操纵',
	'Load must be positioned precisely and stopped, driveway must be adhered to precisely':
		'负载必须精确定位和停止，车道必须精确地固定',
	'Increased movement speed (approx. 1.0 ≤ 1.3 m/s)': '增加移动速度（约1.0 至 1.3 米/秒）',
	nan: '无',
	'No suitable handles or construction parts for applying force': '没有合适的手柄或结构部件来施加力量',
	'No brake when driving on inclinations > 2° (> 3%)': '在坡度 > 2° (> 3%) 的条件下行驶时无制动',
	'Unadjusted castors (e.g. too small on soft or uneven floor)':
		'未调整的脚轮（例如，在柔软或不平坦的地板上脚轮太小）',
	'Defective castors (worn-out, rubbing, stiff, air pressure too low)': '脚轮缺陷（磨损、摩擦、僵硬、气压太低）',
	Nenhuma: '无',
	'Trunk upright or slightly inclined forward, no twisting / Force application height can be selected freely / No hindrance for the legs':
		'躯干直立或略前倾，不扭动 / 施力高度可自由选择 / 不妨碍腿部',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side / Fixed force application height ranging from 0.9 – 1.2 m / None or only slight hindrance for the legs / Predominantly pulling':
		'身体向运动方向倾斜或在一侧拉动负载时轻微扭曲 / 固定力施加高度范围为 0.9 - 1.2 米 / 对腿部没有或仅有轻微阻碍 / 主要为拉动',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view / Significant hindrance for the legs / Constant twisting and/or lateral inclination of the trunk':
		'固定施力高度<0.9或>1.2米、单侧侧向施力或视线受阻/腿部受阻严重/躯干不断扭转和/或侧向倾斜导致身体姿势不当',
	'Trunk upright or slightly inclined forward, no twisting': '躯干直立或略向前倾，不扭曲',
	'Body inclined towards the direction of movement or slight twisting when pulling the load on one side':
		'一侧拉动负载身体向运动方向倾斜或轻微扭曲',
	'Awkward body postures caused by fixed force application height < 0.9 or > 1.2 m , lateral force application on one side or significantly obstructed view':
		'固定施力高度<0.9或>1.2米、单侧侧向施力或视线受阻导致身体姿势不当',
	'Force application height can be selected freely': '可以自由选择施力高度',
	'Fixed force application height ranging from 0.9 – 1.2 m': '固定施力高度范围为 0.9 – 1.2 米',
	'Significant hindrance for the legs': '对腿部有较大阻碍',
	'No hindrance for the legs': '腿部不受阻碍',
	'None or only slight hindrance for the legs': '对腿部没有或只有轻微的阻碍',
	'Constant twisting and/or lateral inclination of the trunk': '躯干不断扭转和/或侧倾',
	'Predominantly pulling': '以拉动为主',
	Postures: '姿势',
	'Direction of force': '力的方向',
	Unfavorable: '不利',
	'Score and risk assessment': '评分和风险评估',
	'Intensity of load': '负荷强度',
	'Probability of physical overload / Possible health consequences': '身体超负荷的可能性/可能的健康风险后果',
	'Physical overload is unlikely / No health risk is to be expected.':
		'不太可能出现身体超负荷/预计不会出现健康风险。',
	'Physical overload is unlikely': '不太可能出现身体超负荷的情况',
	'No health risk is to be expected': '预计不会带来健康风险',
	'Physical overload is possible for less resilient persons /  Fatigue, low-grade adaptation problems which can be compensated for during leisure time.':
		'身体超负荷也可能发生于正常的人。/疲劳，身体超负荷，低度适应问题，但可以在闲暇时间得到补偿。',
	'Physical overload is possible for less resilient persons': '身体承受能力较差的人可能会承受身体负担',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time':
		'疲劳、低度适应问题，可在闲暇时间得到补偿',
	'Physical overload is also possible for normally resilient persons / Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'身体超负荷也可能发生于正常的人。疾病（疼痛），可能包括功能障碍，在大多数情况下是可逆的，没有形态学表现',
	'Physical overload is also possible for normally resilient persons': '身体承受能力正常的人也有可能超负荷',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation':
		'疾病（疼痛），可能包括功能障碍，大多数情况下是可逆的，没有形态学表现',
	'Physical overload is likely / More pronounced disorders and/or dysfunctions, structural damage with pathological significance':
		'可能出现身体超负荷/更明显的疾病和/或功能障碍，具有病理意义的身体损伤',
	'Physical overload is likely': '身体负荷过重',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance':
		'更明显的疾病和/或功能障碍，具有病理意义的身体损伤',
	Measures: '措施',
	' For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		'对于适应能力较差的人，工作场所的重新设计和其他预防措施可能会有所帮助。',
	' Workplace redesign and other prevention measures should be considered.':
		'应考虑工作场所的重新设计和其他预防措施。',
	' Workplace redesign measures are necessary. Other prevention measures should be considered.':
		'工作场所重新设计措施是必要的。还应考虑其他预防措施。',
	Results: '结果',
	Information: '信息',
	'Body parts': '身体部位',
	Charts: '图表',
	'Industrial Site': '工厂',
	'Collection date': '采集日期',
	'Risk range': '风险范围',
	'Failed to fetch sectors': '无法获取部门',
	'Failed to create a new sector': '无法创建新部门',
	'Failed to fetch risk bands': '无法获取风险区间',
	'Sector created successfully': '部门创建成功',
	'Select a sector': '选择一个部门',
	'Insert a workstation': '插入工作站点',
	'Insert the date of collection': '输入收集日期',
	'Select a risk band': '选择风险范围',
	'Graphs of selected body parts': '选定身体部位的图表',
	Summary: '概括',
	'Range of Risk': '风险范围',
	'Job information': '职位信息',
	'File data': '文件数据',
	File: '文件',
	Duration: '期间',
	'Relevant points': '相关要点',
	Frequency: '频率',
	'Most critical move': '最关键的一步',
	'Include conclusion': '包括结论',
	'Include signature': '包括签名',
	'Insert conclusion': '插入结论',
	'Risk track created successfully!': '风险轨迹创建成功！',
	'Create custom risk bands based on your analysis': '根据您的分析创建自定义风险范围',
	'Industrial site status': '工厂现状',
	'Harmful postures': '有害姿势',
	'Inadequate postures': '姿势不合适',
	'Workstation with good postures': '保持良好姿势的工作站',
	'Workers in real danger': '真正处于危险中的工人',
	'Total of videos': '视频总数',
	'Processed minutes': '已处理分钟数',
	'Critical sector': '关键部门',
	'Registered sectors': '已注册部门',
	'Exported reports': '导出的报告',
	'Sectors in alert': '警戒的部门',
	'Main statistics': '主要统计',
	'No. of videos by risk level': '不，按风险等级划分的视频数量',
	Month: '月',
	Year: '年',
	Day: '天',
	'Assessments completed': '评估已完成',
	'Videos by sector': '按行业分类的视频',
	'Percentage of videos by risk': '按风险划分的视频百分比',
	'Ergonomics Management': '人体工学管理',
	'Risk Quotation': '风险报价',
	'Total workstations': '工作站点总数',
	'Total pending evaluations': '待处理评估总数',
	'Total evaluations consolidated': '综合评价总数',
	Evaluations: '评估',
	'Not analyzed': '未分析',
	'Not analysed': '未分析',
	'There are no registered action plans': '尚无输入的行动计划',
	'Acceptable Risk': '可接受风险',
	'Acceptable risk': '可接受风险',
	'Moderate Risk': '中等风险',
	'Moderate risk': '中等风险',
	'High Risk': '高风险',
	'High risk': '高风险',
	'Very High Risk': '非常高的风险',
	'Very high risk': '非常高的风险',
	'Extreme and Imminent Risk': '极端和迫在眉睫的风险',
	'Serious and Imminent Risk': '严重且迫在眉睫的风险',
	'Serious and imminent risk': '严重且迫在眉睫的风险',
	'Serious risk': '严重风险',
	'Serious and imminent': '严重且迫在眉睫',
	Cellule: '细胞',
	'Select the Cellule': '选择细胞',
	'Select the Workstation': '选择工作站点',
	'Working Population': '作业人数',
	Others: '其他的',
	'Stress Level': '压力水平',
	'Risk Level': '风险等级',
	Repetitiveness: '重复性',
	'Cargo Handling': '货物装卸',
	'Work Organization': '工作组织',
	'Score by workstation': '按工作站点评分',
	'Total evaluations': '总评价数',
	Low: '低的',
	Average: '平均的',
	High: '高的',
	'Movement by score': '按分数移动',
	'Risk per Workstation': '每个工作站点的风险',
	Workstations: '工作站点',
	'Higher workstation risk score': '工作站点风险评分较高',
	'Action plans': '行动计划',
	'Planned Actions': '计划的行动',
	Completed: '完成',
	Concluded: '总结',
	Ascending: '上升',
	Deadline: '最后期限',
	Next: '下一个',
	Previous: '以前的',
	delete: '删除',
	Delete: '删除',
	'Do you really want to delete these tracks?': '您确实要删除这些条目吗？',
	'Heads up! the deletion is permanent': '注意！删除是永久性的',
	'Risk ranges deleted successfully': '风险范围删除成功',
	'Description (optional)': '描述（可选）',
	Neck: '脖子',
	Trunk: '躯干',
	UpperArm: '上臂',
	LowerArm: '下臂',
	'Customize bands of risk': '自定义风险范围',
	'Create custom risk strips according to your needs.': '根据您的需要创建自定义风险条款。',
	Name: '名称',
	'Industrial site name': '工厂名称',
	'Enter a name': '输入名称',
	'Please enter a valid name': '请输入一个有效的名称',
	'Please enter a valid phone number': '请输入一个有效的电话号码',
	'Invalid phone number': '无效的电话号码',
	'Insert short description': '插入简短描述',
	'Add new band': '添加新品牌',
	'Create new band': '创建新品牌',
	'Minimum angle': '最小角度',
	'Maximum angle': '最大角度',
	Coloring: '着色',
	Remove: '删除',
	Home: '主页',
	'Manage users': '管理用户',
	Preferences: '优先',
	'My informations': '我的信息',
	'My plan': '我的计划',
	individual: '个人',
	team: '团队',
	business: '商业',
	pilot: '试点',
	yearly: '每年',
	monthly: '每月',
	Yearly: '每年',
	Monthly: '每月',
	User: '用户',
	Supervisor: '主管',
	Master: '负责人',
	Admin: '管理员',
	'Choose a plan different from your current plan': '选择与当前不同的计划',
	'Plan successfully canceled': '计划已成功取消',
	'Password changed successfully': '密码修改成功',
	'Customer plan not found': '未找到客户计划',
	'Customer data not found': '未找到客户数据',
	'User access not found': '未找到用户访问权限',
	'File deleted successfully': '文件删除成功',
	'Check the parameters entered': '检查输入的参数',
	'Number of document characters are invalid': '文档字符数无效',
	'Invalid inserted CPF': '插入的 CPF 无效',
	'Invalid inserted CNPJ': '插入的 CNPJ 无效',
	'It is necessary to spend a day after contracting the service to change the plan': '签约后需花一天时间更改套餐',
	'Forgot password': '忘记密码',
	'Log in': '登录',
	'Please input your password!': '请输入您的密码！',
	'Invalid email or password': '无效的电子邮件或密码',
	'The input is not a valid e-mail!': '输入的电子邮件无效！',
	'Please input your e-mail': '请输入您的电子邮箱',
	'Inactive account, please activate': '账户未激活，请激活',
	Send: '发送',
	'User not found': '未找到用户',
	'Success, check your email box': '成功，请查看您的邮箱',
	'Password recovery': '找回密码',
	'Token invalid': '代码无效',
	'Token expired, generated a new one': '代码已过期，请生成新的代码',
	'Please confirm your password!': '请确认您的密码！',
	'Confirm Password': '确认密码',
	'The two passwords that you entered do not match!': '您输入的两个密码不匹配！',
	Confirm: '确认',
	Generate: '生成',
	Validate: '验证',
	Continue: '继续',
	'Reset token': '重置代码',
	Cancel: '取消',
	flexion: '屈曲',
	turned: '转身',
	extension: '扩大',
	sideways: '侧身',
	crouched: '蹲下',
	downed: '被击落',
	arm_raised: '手臂抬起',
	behind: '在后面',
	raised: '提起',
	none: '无',
	'Registered successfully!': '注册成功！',
	'Just a little longer, check your e-mail inbox': '再等一会儿，检查你的电子邮件收件箱',
	Access: '使用权',
	'Kinebot - Terms of use': 'Kinebot-使用条款',
	'terms of use': '使用条款',
	"Welcome! Let's start": '欢迎！让我们开始吧',
	'Enter your phone number': '输入你的电话号码',
	'Select your contry': '选择你的国家',
	State: '省份',
	'Select your state': '选择您的省份',
	'Select your city': '选择您的城市',
	'Zip code': '邮政编码',
	'Enter your zipcode': '输入你的邮政编码',
	'Enter your address': '输入您的地址',
	'Enter your street number': '输入您的街道号码',
	'Coupon (optional)': '优惠券（可选）',
	Coupon: '优惠券',
	'There are problems on your operation.': '你的操作有问题。',
	Leave: '离开',
	Data: '数据',
	'Final steps': '最后步骤',
	'Card number': '卡号',
	'Enter your credit card number': '输入您的信用卡号',
	'Invalid credit card': '信用卡无效',
	'Card holder name': '持卡人姓名',
	'Enter the card holder name': '输入持卡人姓名',
	'Due date': '到期日',
	"Enter the card's due date": '输入卡的到期日',
	'Invalid month': '月份无效',
	'Invalid card date': '卡日期无效',
	'Enter the security code': '输入安全码',
	'Invalid code': '无效的代码',
	"We are going through maintenance, we'll be back soon.": '我们正在进行维护，很快就会回来',
	'Personal data': '个人资料',
	'Document number': '文件编号',
	'You will only be charged after the free test period, it can be cancelled at anytime.':
		'您只需要在免费试用期结束后付费，并且可以随时取消。',
	Discounts: '折扣',
	'I have read and agree with the': '我已阅读并同意',
	Close: '关闭',
	'Validating coupon': '正在验证优惠券',
	"Couldn't find the coupon": '找不到优惠券',
	Individual: '个人',
	Team: '团队',
	Business: '商业',
	'Enter your document no.': '输入您的文件编号。',
	'Enter a valid document': '输入有效文件',
	'Enter a phone number': '输入电话号码',
	'Enter the zip code': '输入邮政编码',
	'Enter the city': '输入城市',
	'Enter the state': '输入省份',
	'Street number': '门牌号',
	'Under review': '审核中',
	'Insufficient funds': '资金不足',
	'Not accepted': '不接受',
	'Customer already registered': '客户已经注册',
	'Failed to perform transaction': '交易执行失败',
	'email must be unique': '电子邮件必须是唯一的',
	'Error loading video': '加载视频错误',
	'Failed to fetch your plan information.': '无法获取您的计划信息。',
	'Failed to fetch total customer videos.': '无法获取所有顾客视频。',
	'Maximum uploads reached.': '已达到最大上传量。',
	'Maximum minutes reached.': '已达到最大分钟数。',
	'Corrupted video.': '视频损坏。',
	'Maximum users reached': '已达到最大用户数',
	"The 'name' field is required.": '名称的字段是必需的。',
	"The 'email' field is required.": '‘电子邮件’字段是必填项。',
	"The 'country' field is required.": '‘国家’字段是必填项。',
	"The 'state' field is required.": '‘省份’字段是必填项。',
	"The 'city' field is required.": '‘城市’字段是必填项。',
	"The 'address' field is required.": '‘地址’字段是必填项。',
	"The 'street_number' field is required.": '‘街道编号’字段是必填项。',
	"The 'zipcode' field is required.": '‘邮政编码’字段是必填项。',
	'Personal information': '个人信息',
	Localization: '本土化',
	'Full name': '全名',
	'Enter your full name': '输入您的全名',
	'Enter your name': '输入你的名字',
	'Insert a document': '插入文件',
	'Insert a valid document': '插入有效文件',
	Address: '地址',
	'Enter an address': '输入地址',
	Number: '数字',
	'Enter the number': '输入号码',
	Zipcode: '邮政编码',
	'Enter zip code': '输入邮政编号',
	Country: '国家',
	'Select your country': '选择您的国家',
	'Select state': '选择省份',
	City: '城市',
	'Select city': '选择城市',
	'Update completed': '已执行更新',
	'Customer information updated successfully': '客户信息更新成功',
	'Fill in your personal information': '填写您的个人信息',
	'Forgot your password?': '忘记密码了吗？',
	'We will send you an e-mail to regain access to your account, rest assured!':
		'我们将向您发送一封电子邮件以重新获得您帐户的访问权限，请放心！',
	'Back to login': '回到登入',
	'Please enter a valid e-mail address': '请输入有效的电子邮件地址',
	'Enter an e-mail': '输入电子邮件',
	'Oops... Something went wrong!': '注意！有些地方出错了！',
	'Operation performed successfully': '操作已成功执行',
	'Check your email inbox': '检查你的电子邮件收件箱',
	'Create new password': '创建新密码',
	'Your new password must be different from previous used passwords': '您的新密码必须与之前使用的密码不同',
	'Enter your new password': '输入您的新密码',
	'Confirm new password': '确认新密码',
	'The two passwords that you entered do not match': '您输入的两个密码不匹配',
	Save: '保存',
	'Click here to continue': '点击此处继续',
	'Invalid Token': '指令无效',
	'Expired token': '指令已过期',
	trunk: '躯干',
	neck: '脖子',
	left_lower_arm: '左下臂',
	right_lower_arm: '右下臂',
	left_upper_arm: '左上臂',
	right_upper_arm: '右上臂',
	left_knee: '左膝盖',
	right_knee: '右膝盖',
	left_ankle: '左脚踝',
	right_ankle: '右脚踝',
	hip: '臀部',
	left_hand: '左手',
	right_hand: '右手',
	Knee: '膝盖',
	Ankle: '踝',
	Hip: '臀部',
	Hand: '手',
	Qualification: '资格',
	'Select the body parts to display': '选择要显示的身体部位',
	'Light risk': '低风险',
	'Average risk': '评价风险',
	Level: '等级',
	'Limit of bands reached': '已到达该类型的极限',
	'Industrial site': '工业场地',
	Period: '时期',
	Search: '搜索',
	Clear: '清除',
	'Select company': '选择公司',
	'Create new user': '创建新用户',
	Email: '电子邮件',
	Permissions: '权限',
	'Companies - Industrial sites': '公司 - 工业场地',
	'Change password': '更改密码',
	'Current password': '当前密码',
	'New password': '新密码',
	'Confirm password': '确认密码',
	'Enter your current password': '输入当前密码',
	'Enter a new password': '输入新的密码',
	'Password must be at least 6 characters long': '密码必须至少有6个字符长',
	'Repeat your new password': '重复您的新密码',
	"The two passwords don't match, check": '两个密码不匹配，请检查',
	'Sorry time exceeded, try again.': '抱歉，超出时间，请重试。',
	'Invalid current password': '当前密码无效',
	'Update information': '更新信息',
	'Phone number': '电话号码',
	'Account type': '帐户类型',
	'Created in': '创建于',
	'Postal code': '邮政编码',
	'Sorry, something went wrong': '抱歉，出了一些问题',
	Retry: '重试',
	Settings: '设置',
	'My account': '我的账户',
	Plan: '计划',
	'Subscription type': '订阅类型',
	Recurrence: '复发',
	'Expiration date': '截止日期',
	'Plan expiration date': '计划到期日期',
	'Active users': '有效用户',
	'Processed videos': '已处理的视频',
	'Processing time': '处理时间',
	'Additional minutes': '额外分钟',
	'Add credits': '添加积分',
	'Renew subscription': '续订',
	'Change subscription': '更改订阅',
	'Cancel subscription': '取消订阅',
	of: '的',
	minutes: '分钟',
	Test: '测试',
	Minutes: '分钟',
	'Consolidate data': '整合数据',
	'Export report': '导出报告',
	'There is no post with screwdriver / screwdriver': '没有带螺丝刀/螺丝刀的帖子',
	'Data not found': '未找到数据',
	'Requires actions': '需要采取行动',
	'No actions required': '无需采取任何行动',
	'High Risk and Requires Action': '高风险且需要采取行动',
	'Moderate risk and no action required': '较高风险，无需采取行动',
	'Low Risk and No Action Required': '低风险且无需采取行动',
	'Intensity of work': '工作强度',
	'Work organization': '工作组织',
	'Is spontaneous breaks allowed during the workday?': '工作日期间允许临时休息吗？',
	'Is there a diversification of tasks in the performance of your functions?': '在履行职责时，任务是否多样化？',
	'Materials are always available at the workstation, avoiding rush, delay or overload?':
		'工作站是否始终备有材料，避免匆忙、延误或超载？',
	Complexity: '复杂',
	'Movements do not require high dexterity': '动作不需要很高的灵活性',
	'Low level of attention requirement for long periods': '长时间注意力要求低',
	'Workstation is complex and high cognitive demand': '工作站复杂且认知要求高',
	'Workstation is simple and of low cognitive requirement': '工作站简单且认知要求低',
	Displacement: '移位',
	Squat: '蹲',
	Cervical: '宫颈',
	'Left Shoulder/Arm': '左肩/手臂',
	'Right Shoulder/Arm': '右肩/手臂',
	'Left wrist': '左手腕',
	'Right wrist': '右手腕',
	'No repeatability': '无重复性',
	'With repetition': '重复',
	' x per hour': 'X每小时',
	'Without risk': '无风险',
	'Action Level': '行动层面',
	'Complementary resources': '补充资源',
	'Percussion tools': '敲击工具',
	'Hit against': '命中',
	'Present Risk': '当前风险',
	'Low risk': '低风险',
	'Global Ranking': '全球排名',
	'Operator report': '操作员报告',
	'Enter operator report below': '输入下面的操作员报告',
	'Easy to understand information': '信息易于理解',
	' 10 steps per minute': '每分钟 10 步',
	'15 to 25 steps per minute': '每分钟 15 至 25 步',
	'Above 25 steps per minute': '每分钟25步以上',
	'Inform below the displacement of the operator during the workday': '告知操作员在工作日内的位移情况',
	'Regulation notes': '法规监管说明',
	'Spontaneous breaks are allowed during the workday (e.g. drinking water, drinking coffee, going to the bathroom)':
		'工作日期间允许自发休息（例如喝水、喝咖啡、上厕所）',
	'In the performance of their functions, there is diversification of tasks': '在履行职能过程中，任务多样化',
	'Materials are always available at the workstation, avoiding rush, delay or overload':
		'工作站始终有材料可用，避免匆忙、延误或超载',
	'Work intensity': '工作强度',
	Wrist: '手腕',
	'Enter job information below': '在下面输入职位信息',
	'During the operation, the operator taps his hands to adjust parts or components.':
		'在操作过程中，操作人员用手敲击来调整零件或部件。',
	'Add a complementary video for wrist analysis': '添加腕部分析配套视频',
	'Do not include in the analysis': '不包括在分析中',
	'Include external video': '包括外部视频',
	'Enter video name': '输入视频名称',
	'Sledgehammer and/or taps are hits from hard hits (except hit adjustments)': '大锤和/或轻击是重击（打击调整除外）',
	'Light - Quiet': '轻-几乎没有感受',
	'Medium - Some effort is perceived (defined)': '中度-感知到了一定的力',
	'Heavy - Clear effort': '重-明显的力',
	'Very heavy - Sharp effort': '非常重-巨大的力',
	'Next. maximum - Use of torso and limbs to generate greater force':
		'接下来是最大限度 - 使用躯干和四肢产生更大的力量',
	'Workstation Information': '工作站信息',
	'Enter operator difficulty control below': '在下面输入操作员难度控制',
	'Control Difficulty': '控制难度',
	'Select a cost center': '选择成本中心',
	'Enter data about vibration in employee activity below': '在下面输入有关员工活动中振动的数据',
	"Enter below the data on the employee's reaction in the activity": '在下面输入员工在活动中的反应数据',
	'Insert a new reaction': '插入新反应',
	'Insert a new vibration': '插入新振动',
	"Enter below the data of the employee's effort in the activity": '请在下面输入员工在该操作过程当中的用时数据',
	'Insert a new effort': '插入新的操作用时数据',
	'Cost center': '成本中心',
	'Enter a workstation': '进入工作站',
	'Enter the data related to the selected video below': '在下方输入与所选视频相关的数据',
	Staff: '职员',
	'Enter the Company Activities': '进入公司活动',
	'Insert a production line': '插入生产线',
	'Add new range': '添加新范围',
	'industrial site': '工业场地',
	'Production line': '生产线',
	'Select a production line': '选择生产线',
	Role: '角色',
	Code: '代码',
	'Tax Code': '税法',
	'Enter a Tax Number': '输入税号',
	'Insert a valid Tax Number': '插入有效的税号',
	'Select a role': '选择角色',
	'Insert the role': '插入角色',
	'User type': '用户类型',
	Document: '文档',
	'Current plan': '当前计划',
	'Not provided': '未获知',
	'Risk ranges': '风险范围',
	'Local application': '本地应用程序',
	'Units for analysis': '要分析的单位',
	'Select the units for review': '选择将纳入审核的单位',
	Factory: '工厂',
	'Select the industrial sites for analysis': '选择要分析的工业场地',
	'Production Volume': '生产量',
	Investments: '投资',
	'Enter production volumes below': '输入以下产量',
	'Inform below the investments in ergonomics': '告知以下人体工程学方面的投资',
	Date: '日期',
	'Date of reference': '參考日期',
	'Date of the report': '报告日期',
	'Ergonomic analysis of work': '工作人体工学分析',
	Presentation: '推介会',
	'Plant Information': '工厂信息',
	'Insert a company name': '插入公司名称',
	'Risk degree': '危险程度',
	Activity: '活动',
	District: '区',
	Administrative: '行政的',
	'Work Shift': '工作班次',
	Operational: '操作',
	'Direct hourly worker': '直接小时工',
	'Indirect hourly worker': '间接计时工',
	'Monthly worker': '月薪工人',
	'Normal working hours': '正常工作时间',
	'Include checklists related to this analysis below': '请在下方列出与此分析相关的清单',
	'Non-standard scale': '非标准尺度',
	'Lower members': '腿',
	'Left elbow': '左肘',
	'Right elbow': '右肘',
	'Left shoulder': '左肩',
	'Right shoulder': '右肩',
	'Left knee': '左膝',
	'Right knee': '右膝',
	'Left ankle': '左脚踝',
	'Right ankle': '右脚踝',
	'Left hand': '左手',
	'Right hand': '右手',
	'Video description': '视频描述',
	Below: '以下',
	Above: '多于',
	Checklist: '检查清单',
	'Frequency and duration': '频率和持续时间',
	'Date of collection': '收集日期',
	'Load more than 10 kg of repetitive load or rapid build up of force': '承受超过10公斤的重复负荷或打击',
	Medium: '中等的',
	Invalid: '无效的',
	Safe: '安全的',
	Info: '信息',
	Danger: '危险',
	safe: '安全的',
	info: '信息',
	warning: '警告',
	danger: '危险',
	'No corrective action needed': '无需采取纠正措施',
	'Corrective action as soon as possible': '尽快采取纠正措施',
	'High-priority corrective action': '高优先级纠正措施',
	'Immediate corrective action': '立即采取纠正措施',
	'Unable to show results': '无法显示结果',
	Line: '线',
	Effort: '努力',
	Reaction: '反应',
	Vibration: '振动',
	'Beats against': '击败',
	Percussion: '打击乐器',
	Hands: '手',
	'Shoulders/Arms': '肩膀/手臂',
	Left: '左侧',
	Right: '右侧',
	Exit: '出口',
	Filters: '筛选器',
	'E-mail': '电子邮件',
	'Enter a valid e-mail': '输入有效的电子邮件',
	'Enter your password': '输入密码',
	Login: '登录',
	Dashboard: '仪表板',
	Upload: '上传',
	EWA: '初步人体工程学评估',
	EAW: '人体工程学工作分析',
	'Comparison over the last 6 months': '过去 6 个月的比较',
	'Are you sure?': '你确定吗？',
	'Are you sure you want to quit? You have an upload in process.': '您确定要退出吗？您正在上传。',
	'Select a company': '选择公司',
	'Select an industrial site': '选择工业地点',
	'Select a line': '选择一行',
	'Select the workstation': '选择工作站',
	'Select the activity': '选择活动',
	'No data available': '无可用数据',
	'There is no registered data.': '沒有登记数据',
	Logout: '登出',
	"Approximated values, depends mainly on the filming methods. In case there is no data, the algorithm didn't find the points to determine the angle, please review the filming method used.":
		'近似值，主要取决于拍摄方法。如果没有数据，算法找不到确定角度的点，请检查使用的拍摄方法。',
	'The results obtained from each body part can be affected by the method and position of filming, the identification of the person in the analysis can suffer interference from objects present in the room. The angle data shown are approximate values.':
		'从身体各个部位获得的结果可能会受到拍摄方法和位置的影响，分析中对人员的识别可能会受到房间内物体的干扰。显示的角度数据为近似值。',
	Reference: '参考',
	'Investigate and change immediately': '立即调查并改变',
	'Analysis of the movement by angle': '通过角度分析运动',
	'Analysis of the movement by score': '按照乐谱分析动作',
	'Analysis of the movement & Body parts': '动作和身体部位分析',
	'Report types': '报告类型',
	'Generate reports': '生成报告',
	Finish: '结束',
	'Description already exists!': '描述已存在！',
	'Enter a new sector': '进入新领域',
	'Do you wish to delete this sector?': '您是否希望删除该部分？',
	'This sector is used in other reports, do you really wish to delete it?':
		'此部分已在其他报告中使用，确实要删除它吗？',
	'Sector created successfully!': '分区创建成功！',
	"This operation couldn't be completed": '此操作无法完成',
	Sectors: '部门',
	'Fill in the essential information below.': '填写以下基本信息。',
	'Select the sector from the above analysis': '从上述分析中选择行业',
	'Select the sector for the analysis': '选择要分析的行业',
	Add: '添加',
	'Enter the workstation': '进入工作站',
	'Enter the date the collection was made': '输入收款日期',
	'Select the tool for the analysis and the type of report you wish to generate.':
		'选择您想要生成的分析工具和报告类型。',
	Tool: '工具',
	'Pick the tool above': '选择上面的工具',
	'Select the type of tool': '选择工具类型',
	Report: '报告',
	'Select the type of report above': '选择上面的报告类型',
	'Select the type of report': '选择报告类型',
	Squatting: '蹲踞',
	Video: '视频',
	'Very high': '极高风险',
	'Not identified': '未确定',
	Comments: '评论',
	Filter: '筛选',
	Company: '公司',
	'Select the company': '选择公司',
	'Select the industrial site': '选择工业地点',
	Function: '功能',
	'Enter the function': '输入相应岗位',
	'Customized reports': '定制报告',
	"We weren't able to change your password": '我们无法更改您的密码',
	'Warning!': '警告！',
	'Failed to fetch requested information': '无法获取请求的信息',
	'Do you really wish to cancel your plan?': '您确实要取消您的计划吗？',
	'After cancelling your plan, when the expiration date comes, you will have no more access to the platform.':
		'取消计划后，到期后您将无法再访问该平台。',
	"We weren't able to cancel your plan": '我们无法取消你的计划',
	"Select a plan that's different from your current one": '选择与当前计划不同的计划',
	"We couldn't process the operation": '我们无法处理该操作',
	'Plan cancelled on': '计划取消于',
	'Plan switch': '计划切换',
	'You have recently changed your plan': '您最近更改了计划',
	'Plan type': '计划类型',
	'New plan start date': '新计划开始日期',
	'User limit': '用户限制',
	'Upload limit': '上传限制',
	'Processing time limit': '处理时限',
	'Cancel plan': '取消计划',
	'Error when trying to verify industrial sites from company.': '尝试验证公司的工业站点时出错。',
	'Error when trying to delete the company': '尝试删除公司时出错',
	'Enter a permission': '输入权限',
	'Select a permission': '选择权限',
	'Edit user': '編輯用戶',
	'User changed successfully': '用户变更成功',
	Permission: '允许',
	'Do you wish to delete this user?': '您想删除该用户吗？',
	Active: '积极的',
	Disabled: '已禁用',
	'Manage companies': '管理公司',
	'Manage industrial sites': '管理工业场地',
	'Heads up! This company is not empty': '注意！此公司不为空',
	'To continue, first remove industrial sites belonging to this company.': '要继续，首先删除属于该公司的工业用地。',
	'All industrial sites in this company will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'该公司的所有工业站点及其各自的上传和报告都将被删除。是否继续？',
	'All uploads and reports from this industrial site will be deleted. This action cannot be undone. Do you wish to continue?':
		'来自该工业场地的所有上传和报告都将被删除。此操作无法撤消。是否继续？',
	'Failed to update information': '更新信息失败',
	'Edit company': '編輯公司',
	Loading: '加载中',
	'Loading...': '加载中...',
	'Company name': '公司名称',
	'Industrial sites': '工业场地',
	'Updated information': '更新信息',
	'Error while changing the name of the industrial site': '更改工厂名称时出错',
	Inactive: '不有效的',
	All: '全部',
	'Organization created successfully': '组织创建成功',
	'Organization updated successfully': '组织更新成功',
	'Organization deleted successfully': '已成功删除组织',
	'Data created successfully': '数据创建成功',
	'Data updated successfully': '数据更新成功',
	'Data deleted successfully': '数据删除成功',
	'Please contact support to reactivate it': '请联系支持人员重新激活它',
	'Create a new workstation': '创建新工作站',
	'Create a new sector': '创建新部门',
	'1 - Acceptable posture if not repeated or kept for long periods': '- 如果不重复或长时间保持，则姿势可以接受',
	'2 - Investigate; possibility to request for change; it is convenient to introduce alterations':
		'- 调查；可以请求变更；方便进行变更',
	'3 - Investigate; make changes quickly': '- 调查；迅速做出改变',
	'4 - Immediate change': '- 立即改变',
	'Acceptable posture if not repeated or kept for long periods': '如果不重复或长时间保持，姿势可以接受',
	'Investigate; possibility to request for change; it is convenient to introduce alterations':
		'调查；可以请求变更；便于引入变更',
	'Investigate; make changes quickly': '调查；迅速做出改变',
	'Immediate change': '立即改变',
	Ergonomist: '人体工程学家',
	'Left lower arm': '左下臂',
	'Right lower arm': '右下臂',
	'Left upper arm': '左上臂',
	'Right upper arm': '右上臂',
	Type: '类型',
	'Manual Lifting Report': '手动起重报告',
	'Enter the job characteristics below.': '在下面输入工作特征。',
	'Gender of the worker': '工人性别',
	'Select the gender': '选择性别',
	Age: '年龄',
	'Select the age': '选择年龄',
	'How many workers are lifting the object?': '有多少工人正在吊运该物体？',
	'Which hands are used?': '用哪只手？',
	One: '一',
	Two: '二',
	Three: '三',
	'Both hands': '双手',
	'One hand': '一只手',
	'Select the coupling quality': '选择联轴器质量',
	'Select the lifting frequency': '选择升降频率',
	'Select the lifting duration': '选择起重时长',
	'Lifting frequency (lifts per minute)': '升降频率（每分钟升降次数）',
	Poor: '不好的/糟糕的',
	Fair: '公平的',
	Good: '好的',
	'1 hour': '1小时',
	'1 - 2 hours': '1-2小时',
	'2 - 8 hours': '2- 8 小时',
	'Enter the task variables below.': '在下面输入任务变量',
	'Please enter a value': '请输入一个值',
	'Weight of the object': '物体的重量',
	'Distance the object is moved vertically': '物体垂直移动的距离',
	'Vertical distance of the object at the beginning of handling, relative to the ground':
		'开始搬运时物体相对于地面的垂直距离',
	'Horizontal distance of the object relative to the body': '物体相对于身体的水平距离',
	'Rotation angle': '旋转角度',
	'Recommended Weight Limit (RWL)': '建议重量限制 (RWL)',
	'Lifting Index (LI)': '提升指数 (LI)',
	'Coupling factor': '耦合因子，手和物体之间的耦合（抓握）',
	'Frequency factor': '频率因子',
	'lifts / min': '升降次数/分钟',
	'Report date': '报告日期',
	Comment: '评论',
	Details: '细节',
	Risk: '风险',
	'Very low risk': '极低风险',
	Measurements: '测量',
	'Lifted by': '解除',
	'1 worker': '1名工人',
	'2 workers': '2名工人',
	'3 workers': '3名工人',
	Using: '使用',
	'only one hand': '只有一只手',
	'both hands': '双手',
	'Reference weight': '参考重量',
	'Enter the required values': '输入所需值',
	'Report created successfully': '报告创建成功',
	'Report updated successfully': '报告更新成功',
	'Want to delete this report?': '想要删除此报告吗？',
	'Report deleted successfully': '报告删除成功',
	'Comment updated successfully': '评论更新成功',
	'Document created successfully': '文档创建成功',
	Conclusions: '结论',
	Conclusion: '结论',
	'The weight to be lifted': '需要举起的重量',
	'is greater than the recommended weight limit (RWL)': '大于建议重量限制 (RWL)',
	'is less than the recommended weight limit (RWL)': '小于建议重量限制 (RWL)',
	' The lifting index (LI)': '提升指数（LI）',
	'is more than 3.': '大于 3。',
	'is more than 2 and less than 3.': '大于 2 且小于 3。',
	'is more than 1,5 and less than 2.': '大于 1.5 且小于 2。',
	'is more than 1 and less than 1,5.': '大于 1 且小于 1.5。',
	'is less than 1.': '小于 1。',
	' There is a very high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		'风险非常高。这些数值表明，这种升降机对大多数健康的工业工人来说都是危险的。',
	' There is a high risk. These values indicate that this lift would be hazardous for a majority of healthy industrial workers.':
		'风险很高。这些数值表明，这种升降机对大多数健康的工业工人来说都是危险的。',
	' There is a moderate risk. These values indicate that this job is somewhat stressful.':
		'风险中等。这些值表明这份工作有一定的压力。',
	' There is a low risk. These values indicate that this job is adequate for the majority of industrial workers, but some may have trouble.':
		'风险较低。这些数值表明，这项工作对于大多数产业工人来说已经足够了，但有些人可能会遇到困难。',
	' There is a very low risk. These values indicate that this job is adequate for the majority of industrial workers.':
		'风险很低。这些数值表明这项工作对于大多数产业工人来说已经足够了。',
	Male: '男性',
	Female: '女性',
	Man: '男人',
	Woman: '女士',
	'20 to 45 years old': '20至45岁',
	'20 to 45': '20 至 45',
	'< 20 years old': '< 20岁',
	'> 45 years old': '>45岁',
	'Download PDF': '下载 PDF',
	'The most critical variables are listed below with suggestions': '下面列出了最关键的变量并给出了建议',
	'Bring the load closer to the worker by removing any horizontal barriers or reducing the size of the object. Lifts near the floor should be avoided.':
		'移除水平障碍物或减小物体尺寸，使负载更靠近工人。应避免将升降机靠近地板。',
	'Raise/lower the origin/destination of the lift. Avoid lifting near the floor or above the shoulders.':
		'升高/降低升降机的起点/终点。避免将升降机举到靠近地板或高于肩膀的位置。',
	'Reduce the vertical distance between the origin and the destination of the lift.':
		'减少电梯起点和终点之间的垂直距离。',
	'Move the origin and destination closer together to reduce the angle of twist, or move them further apart to force the worker to turn the feet and step, rather than twist the body.':
		'将起点和终点移近一点，以减少扭转角度，或者将它们移远一点，以迫使工人转动脚和步伐，而不是扭转身体。',
	'Reduce the lifting frequency rate, reduce the lifting duration, or provide longer recovery periods (i.e., light work period).':
		'降低举重频率，减少举重时间，或提供更长的恢复期（即轻度工作期）。',
	'Improve the hand-to-object coupling by providing optimal containers with handles or handhold cutouts, or improve the handholds for irregular objects.':
		'通过提供带有手柄或手握切口的最佳容器来改善手与物体的接触，或改善不规则物体的手柄。',
	'Consider using both hands when lifting the weight.': '举重时考虑用双手。',
	'One handed': '一只手',
	'Distance H': '距离H',
	'Distance Vc': '距离Vc',
	'Distance Dc': '距离Dc',
	'Angle A': '角度A',
	'Frequency/Duration': '频率/持续时间',
	Coupling: '耦合',
	Characteristics: '特征',
	Variables: '变量',
	Result: '结果',
	'Choose a gender': '选择性别',
	Gender: '性别',
	Pairs: '一对',
	'Report Results - Revised Strain Index': '报告结果-修订后的应变指数 （RSI）',
	'Intensity of exertion': '运动强度',
	'Efforts per minute': '每分钟用力',
	'Number of exertions': '用力次数',
	'Observation time': '观察时间',
	'Duration per exertion': '每次运动持续时间',
	'Hand/wrist posture': '手/腕姿势',
	'Type of wrist posture': '手腕姿势类型',
	'Hand/wrist angle': '手/腕角度',
	'Duration of task per day': '每日任务时长',
	'Revised Strain Index (RSI)': '修订后的应变指数 （RSI）',
	'Risk factor': '风险系数',
	Input: '输入',
	'Job is probably safe': '工作可能安全',
	'Job is probably hazardous': '工作可能危险',
	Degrees: '程度',
	'Duration/day': '持续时间/天',
	'Intensity of exertion (Borg Scale - BS)': '运动强度（博格量表 - BS）',
	'Somewhat Hard': '有点难',
	Hard: '难的',
	'Very Hard': '很难',
	'Near Maximal': '接近最大值',
	'Risk factors 1': '风险系数1',
	'Risk factors 2': '风险系数2',
	'Barely noticeable or relaxed effort (BS)': '几乎察觉不到或放松的力',
	'Noticeable or definite effort (BS)': '明显或明确的力',
	'Obvious effort; Unchanged facial expression (BS)': '明显的用力；面部表情不变',
	'Obvious effort; No changes in facial expression (BS)': '明显的用力；面部表情没有明显的变化',
	'Substantial effort; Changes expression (BS': '非常用力；面部表情改变',
	'Uses shoulder or trunk for force (BS': '使用肩膀或躯干发力',
	'Number of exertions observed': '观察到的运动次数',
	'Total observation time': '总观察时间',
	'Average single exertion time': '平均单次用力时间',
	'(total exertions * single exertion time) must be less than or equal to the total observation time':
		'（总用力次数 * 单次用力时间）必须小于或等于总观察时间',
	seconds: '秒',
	hours: '小时',
	Extension: '扩大',
	Flexion: '弯曲',
	'Revised Strain Index (Moore and Garg)': '修订后的应变指数 （RSI）',
	'Report Results': '报告结果',
	'Revised Strain Index': '修订后的应变指数 （RSI）',
	'(Moore and Garg)': '（摩尔和加格）',
	'REBA Report Results': 'REBA 报告结果',
	'REBA score of the workplace': '工作REBA评分',
	'Left leg': '左腿',
	'Right leg': '右腿',
	'Load less than 5kg (intermittent)': '负载小于5kg（间歇）',
	'Load from 5kg to 20kg (intermittent)': '负载从5kg到20kg（间歇）',
	'Load from 5kg to 20kg (static or repetitive)': '负载从 5kg 到 20kg（静态或重复）',
	'Greater than 20 kg of repetitive load or rapid build up of force': '更大负荷 20kg 重复负荷或撞击',
	Unacceptable: '不可接受',
	Force: '力量',
	Load: '负载',
	'Select the load': '选择负载',
	'Select the number of repetitions': '选择重复次数',
	'Score left': '左侧分数',
	'Score right': '右侧分数',
	Acceptable: '可接受',
	Investigate: '调查',
	'Investigate and change soon': '尽快调查并需要很快改变',
	'Investigate and change now': '立即调查并立即改变',
	'Investigate and Implement change': '调查并实施改变',
	'Implement change': '实施改变',
	'REBA Score': 'REBA 评分',
	'The 4 most critical body parts': '4个最关键的身体部位',
	'Viewing the selected body part': '查看选定的身体部位',
	minute: '分钟',
	'Requested video data is old, please upload to continue': '请求的视频数据已旧，请上传后继续',
	'View score': '查看分数',
	'Unable to view': '无法查看',
	'Worst grade found in time': '及时发现最差级别',
	'Worst score': '最差成绩',
	'The results obtained in each body part may be impacted by the shape and position of filming, and the identification of the person being analyzed may be interfered by objects present in the operating location. The angle data shown are approximate values, dependent on the adopted filming technique. If there is no data, the algorithm did not identify the points to determine the angle, so it is important to observe filming techniques.':
		'拍摄时拍摄的形状和位置可能会影响身体各个部位的结果，并且被分析人员的识别可能会受到操作位置中存在的物体的干扰。显示的角度数据是近似值，取决于所采用的拍摄技术。如果没有数据，则算法没有识别出确定角度的点，因此观察拍摄技术很重要。',
	'Angle by time': '按时间角度',
	'Create risk range': '创建风险范围',
	'Report Results - Angle by Time': '报告结果 - 按时间计算的角度',
	'Please fill all required fields': '请填写所有必填字段',
	'Do you want to delete this action plan?': '是否删除该行动计划？',
	'Action plan created successfully': '行动计划创建成功',
	'Action plan updated successfully': '行动计划更新成功',
	'Create action plan': '制定行动计划',
	'Update action plan': '更新行动计划',
	'Please enter a valid description': '请输入有效的描述',
	'Action plan description': '行动计划描述',
	'Please enter a valid action plan description': '请输入有效的行动计划描述',
	'Person responsible': '负责人',
	Responsible: '负责任的',
	'Who is responsible for this action plan?': '谁负责这个行动计划？',
	'Describe the situation found and the preventive measures already implemented (if exist)':
		'描述发现的情况及已实施的预防措施（如果有）',
	'Finish editing your other task first': '首先完成编辑其他任务',
	'Add a new task': '添加新任务',
	History: '历史',
	'Finish by': '完成于',
	'On time': '按时',
	Overdue: '逾期',
	'Finishing in a week': '一周内完成',
	'Person responsible updated': '负责人更新',
	'Deadline updated': '截止日期已更新',
	Tasks: '任务',
	'Task created': '任务已创建',
	'Task deleted': '任务已删除',
	'Task renamed': '任务已重命名',
	'Task type changed': '任务类型已改变',
	'Task and type changed': '任务和类型已更改',
	'Task checked': '任务已检查',
	'Task unchecked': '任务未检查',
	'Action plan created': '制定行动计划',
	'Action plan renamed': '行动计划更名',
	'Status changed': '状态已改变',
	'Deadline changed': '截止日期已更改',
	by: '经过',
	'Successfully updated action plan data': '成功更新行动计划数据',
	'Responsible user changed': '负责用户已更改',
	'Action plan title': '行动计划标题',
	'Enter action plan title': '输入行动计划标题',
	Title: '标题',
	'Select a file': '选择一个文件',
	'Select an option': '选择一个选项',
	'Please enter a valid title': '请输入有效的标题',
	'Please enter a responsible': '请输入一个负责任的',
	'Please enter a valid date': '请输入一个有效的日期',
	'Please select a condition': '请选择条件',
	'Search company': '搜索公司',
	'Search industrial site': '搜索工厂',
	'Enter a description for your action plan.': '输入您的行动计划的描述。',
	'Select a condition': '选择条件',
	Eliminate: '消除',
	Control: '控制',
	Compensate: '补偿',
	ELIMINATE: '消除',
	CONTROL: '控制',
	COMPENSATE: '补偿',
	'Error, cannot get': '错误，无法获取',
	from: '从',
	'Total duration of this sub-activity per working day': '每个工作日该子活动的总时长',
	'Total duration per day worked': '每天工作总时长',
	'Force exerted on hand/fingers (L|R)': '手/手指上施加的力量（左|右）',
	'Power transfer/gripping conditions': '动力传输/抓握条件',
	'gripping conditions': '抓握条件',
	'Power transfer': '力传输',
	'Type of force exertion in the finger/hand area within a  “standard minute”':
		'“标准分钟”内手指/手部区域施加的力量类型',
	'Force transfer / gripping conditions': '力传递/抓握条件',
	'Hand/arm position and movement': '手/手臂的位置和运动',
	'Unfavourable working conditions': '不利的工作条件',
	'Work organization / temporal distribution': '工作组织/时间分配',
	'Body posture/movement': '身体姿势/动作',
	'Very low': '非常低',
	Moderate: '中等',
	'Peak forces': '峰值力',
	'Powerful hitting': '强大的冲击',
	'Up to 15% Fmax. Button actuation, shifting, ordering, material guidance, insertion of small parts.':
		'高达 15% Fmax。按钮启动、移动、排序、材料引导、小部件插入。',
	'Up to 30% Fmax. Gripping, joining small work pieces by hand or with small tools.':
		'高达 30% Fmax。用手或小工具夹持、连接小工件。',
	'Up to 50% Fmax. Turning, winding, packaging, grasping, holding or joining parts, pressing in, cutting, working with small powered hand tools.':
		'高达 50% Fmax。转动、卷绕、包装、抓取、握持或连接零件、压入、切割、使用小型电动手动工具作业。',
	'Up to 80% Fmax. Cutting involving major element of force, working with small staple guns, moving or holding parts or tools.':
		'最高可达 80% Fmax。切割时需要用到较大的力量，使用小型钉枪，移动或握住零件或工具。',
	'More than 80% Fmax. Tightening, loosening bolts, separating, pressing in.':
		'超过80% Fmax。紧固、松开螺栓、分离、压入。',
	'Hitting with the ball of the thumb, palm of the hand or fist.': '用拇指指腹、手掌或者拳头击打。',
	'Optimum force transfer/application': '优化力量传递/应用',
	'Restricted force transfer/application': '限制力量的传递/应用',
	'Force transfer/application considerably hindered': '力量的传递/应用受到很大阻碍',
	'Optimum force application': '最佳用力',
	'Restricted force application': '限制用力',
	'Force application considerably hindered': '用力受到很大阻碍',
	'Working objects are easy to grip (e.g. bar-shaped, gripping grooves) / good ergonomic gripping design (grips, buttons, tools).':
		'工作物体易于抓握（例如条形、抓握凹槽）/良好的人体工程学抓握设计（握把、按钮、工具）。',
	'Greater holding forces required / no shaped grips.': '需要更大的保持力/无成形的抓握力。',
	'Working objects hardly possible to grip (slippery, soft, sharp edges) / no or only unsuitable grips.':
		'工作物体难以抓握（滑、软、边缘锋利）/无法抓握或无法适合抓握。',
	Restricted: '受限制的',
	Unfavourable: '不利的',
	'Position or movements of joints in the middle (relaxed) range, only rare deviations, no continuous static arm posture, hand-arm rest possible as required.':
		'关节的位置或运动处于中间（放松）范围，仅有罕见的偏差，没有连续的静态手臂姿势，可根据需要让手臂休息。',
	'Occasional positions or movements of the joints at the limit of the movement ranges, occasional long continuous static arm posture.':
		'关节偶尔处于运动范围的极限位置或运动，偶尔长时间连续保持静止手臂姿势。',
	'Frequent positions or movements of the joints at the limit of the movement ranges, frequent long continuous static arm posture.':
		'关节频繁处于运动范围的极限位置或运动，频繁长时间保持手臂静止姿势。',
	'Constant positions or movements of the joints at the limit of the movement ranges, constant long continuous static arm posture.':
		'关节在运动范围的极限内保持恒定的位置或运动，手臂保持长时间连续的静态姿势。',
	'Frequent variation of the physical workload situation due to other activities (including other types of physical workload) / without a tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'由于其他活动（包括其他类型的体力工作量）导致体力工作量情况频繁变化/在单个工作日内，某一类型的体力工作量中没有紧密连续的较高体力工作量。',
	'Rare variation of the physical workload situation due to other activities (including other types of physical workload) / occasional tight sequence of higher physical workloads within one type of physical workload during a single working day.':
		'由于其他活动（包括其他类型的体力工作量）导致体力工作量情况很少发生变化/在单个工作日内，某一类型的体力工作量中偶尔会出现紧密连续的较高体力工作量。',
	'No/hardly any variation of the physical workload situation due to other activities (including other types of physical workload) / frequent tight sequence of higher physical workloads within one type of physical workload during a single working day with concurrent high load peaks.':
		'体力负荷情况没有/几乎没有因其他活动（包括其他类型的体力负荷）而发生变化 / 在一个工作日内，某一类型的体力负荷中经常出现紧密连续的较高体力负荷，并同时出现高负荷峰值。',
	'There are no unfavourable working conditions, i.e. reliable recognition of detail / no dazzle / good climatic conditions.':
		'没有不利的工作条件，即可可靠地识别细节/无眩光/良好的气候条件。',
	'Occasionally impaired detail recognition due to dazzle or excessively small details difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'偶尔，由于眩光或过小的细节、通风、寒冷、潮湿等困难条件和/或噪音导致的注意力分散，细节识别会受到影响。',
	'Frequently impaired detail recognition due to dazzle or excessively small details frequently difficult conditions such as draught, cold, moisture and/or disturbed concentration due to noise.':
		'由于眩光或过小的细节经常导致细节识别能力受损，经常出现诸如通风、寒冷、潮湿和/或噪音导致注意力不集中等困难条件。',
	'Alternation between sitting and standing, alternation between standing and walking, dynamic sitting possible':
		'坐立交替、站行交替，可动态坐姿',
	'Trunk inclined forward only very slightly': '躯干仅略微向前倾斜',
	'No twisting and/or lateral inclination of the trunk identifiable': '未发现躯干扭曲和/或侧倾',
	'Head posture': '头部姿势',
	'No gripping above shoulder height / no gripping at a distance from the body':
		'禁止高于肩膀的抓握/禁止远离身体的抓握',
	'Predominantly sitting or standing with occasional walking': '主要坐着或站着，偶尔走动',
	'Trunk with slight inclination of the body towards the work area': '身体略微向工作区域倾斜',
	'Occasional twisting and/or lateral inclination of the trunk identifiable': '偶尔可识别躯干的扭曲和/或侧向倾斜',
	'Occasional deviations from good â€œneutralâ€ head posture/movement': '偶尔偏离良好的“中性”头部姿势/运动',
	'Occasional gripping above shoulder height / occasional gripping at a distance from the body':
		'偶尔抓握高于肩膀高度的物体 / 偶尔抓握距离身体较远的物体',
	'Exclusively standing or sitting without walking': '只站立或坐着，不走路',
	'Trunk clearly inclined forward and/or frequent twisting and/or lateral inclination of the trunk identifiable':
		'躯干明显向前倾斜和/或可识别躯干频繁扭转和/或侧倾',
	'Frequent deviations from good â€œneutralâ€ head posture/movement': '经常偏离良好的“中性”头部姿势/运动',
	'Head posture hunched forward for detail recognition / restricted freedom of movement':
		'头部姿势向前弯腰以识别细节，限制头部活动自由',
	'Frequent gripping above shoulder height / frequent gripping at a distance from the body':
		'频繁抓握高于肩膀的物体/频繁抓握远离身体的物体',
	'Trunk severely inclined forward / frequent or long-lasting bending': '躯干严重前倾/频繁或长时间弯曲',
	'Work being carried out in a kneeling, squatting, lying position': '以跪姿、蹲姿、卧姿进行的工作',
	'Constant twisting and/or lateral inclination of the trunk identifiable': '可识别躯干持续扭转和/或侧倾',
	'Body posture strictly fixed / visual check of action through magnifying glasses or microscopes':
		'身体姿势严格固定/通过放大镜或显微镜目视检查动作',
	'Constant deviations from good â€œneutralâ€ head posture/movement': '持续偏离良好的“中性”头部姿势/运动',
	'Constant gripping above shoulder height / constant gripping at a distance from the body':
		'持续高于肩膀的抓握 / 持续远离身体的抓握',
	'Manual Handling': '手工处理',
	'Manual Handling Operations': '手动操作',
	'KIM - Manual Handling Operations': 'KIM - 手动操作',
	'Total duration per working day': '每个工作日的总时长',
	'Probability of physical overload': '身体超负荷的可能性',
	'Possible health consequences': '可能的健康后果',
	'Physical overload is unlikely.': '身体超负荷的可能性不大。',
	'No health risk is to be expected.': '预计不会存在任何健康风险。',
	'None.': '没有任何。',
	'Physical overload is possible for less resilient persons.': '对于适应力较差的人来说，身体可能会承受不住负担。',
	'Fatigue, low-grade adaptation problems which can be compensated for during leisure time.':
		'疲劳、低度适应问题，可以在闲暇时间得到补偿。',
	'For less resilient persons, workplace redesign and other prevention measures may be helpful.':
		'对于不太有韧性的人来说，重新设计工作场所和其他预防措施可能会有所帮助。',
	'Physical overload is possible for normally resilient persons.':
		'对于通常具有弹性的人来说，身体也有可能承受不住负担。',
	'Disorders (pain), possibly including dysfunctions, reversible in most cases, without morphological manifestation.':
		'疾病（疼痛），可能包括功能障碍，大多数情况下是可逆的，没有形态学表现。',
	'Workplace redesign and other prevention measures should be considered.':
		'应该考虑重新设计工作场所并采取其他预防措施。',
	'Physical overload is likely.': '可能会出现身体超负荷的情况。',
	'More pronounced disorders and/or dysfunctions, structural damage with pathological significance.':
		'更明显的疾病和/或功能障碍，具有病理意义的结构损伤。',
	'Workplace redesign measures are necessary. Other prevention measures should be considered.':
		'工作场所重新设计措施是必要的。还应考虑其他预防措施。',
	'Slightly increased': '略有增加',
	'Substantially increased': '大幅增加',
	'Type of force exertion in the finger/hand area': '手指/手部区域的力量施加类型',
	'Intensity of force exertion in the finger/hand area within a “standard minute”':
		'标准分钟内手指/手部区域的力量施加强度',
	'No measures need to be taken.': '无需采取任何措施。',
	Holding: '保持',
	Moving: '移动',

	'Left arm': '左臂',
	'Right arm': '右臂',
	'Time and force': '时间和力量',
	Conditions: '状况',
	Posture: '姿势',
	'Select the body parts you are interested in': '选择您感兴趣的身体部位',
	'Select all': '全选',
	'Shoulder L': '左肩部 ',
	'Shoulder R': '右肩部',
	'Elbow L': '弯头 L左肘部',
	'Elbow R': '肘部 R右肘部',
	'Leg L': '左腿 ',
	'Leg R': '右腿',
	Cycle: '循环',
	'Select one of the vehicles or a wheel type': '选择一种车辆或车轮类型',
	Conveyors: '传送带',
	Cranes: '起重机',
	'Increased movement speed (approx. 1.0 up to 1.3 m/s)': '增加移动速度（约 1.0 至 1.3 米/秒）',
	'length must be less than or equal to 50 characters long': '长度必须小于或等于 50 个字符',
	'Ergonomic Workplace Analysis': '初步人体工程学分析',
	'Ergonomic Analysis': '人体工程学分析',
	'Ergonomic analysis': '人体工程学分析',
	'Basic information': '基本信息',
	'Basic Information': '基本信息',
	"To generate this document, it's necessary that the EWA's must be consolidated, so the list below only shows the consolidated EWA's.":
		'为了生成此文档，必须合并 EWA, 因此下面的列表仅显示合并的 EWA。',
	'Report Information': '报告信息',
	'Report name': '报告名称',
	'Enter report name': '输入报告名称',
	'Select a Company': '选择公司',
	'Select the reference date': '选择参考日期',
	'Select the report date': '选择报告日期',
	'File Name': '文件名',
	'Created At': '创建于',
	'Date of creation': '创建日期',
	'Analyst Name': '分析师姓名',
	Submit: '提交',
	Signatures: '签名',
	'Enter with a role': '以角色进入 输入角色',
	'Enter with a name of responsible': '输入负责人的名字',
	'Add a new signature': '添加新签名',
	'Responsible name': '负责人姓名',
	'Check selected Files': '检查选定的文件',
	'Files List': '文件列表',
	'Selected EWA': '选择“人体工程学工作分析”',
	'EWA created successfully': '“人体工程学工作分析”报告成功上传',
	'Failed to upload EWA report.': '无法上传“初步人体工程学评估”报告',
	'Preliminary Ergonomic Analyzes': '初步人体工程学分析',
	'Selected Files': '选定文件',
	'Worst Score': '最差成绩',
	'Open File List': '打开文件列表',
	'EWA deleted successfully': '已成功删除 EWA',
	Men: '男性',
	Women: '女性',
	Likelihood: '可能性',
	Exposure: '暴露',
	'Possible Injuries/Harms': '可能的伤害/危害',
	Job: '工作',
	'Assignment note': '作业说明',
	'Weight Limit recommended (WLR)': '建议重量限制 (WLR)',
	'Lifting index (LI)': '提升指数（LI）',
	Particularities: '特征',
	Production: '生产',
	'Working regime': '工作制度',
	'New document': '新建文档',
	'File history': '文件历史记录',
	'Fill in the details above': '填写以上详细信息',
	'Risk Acceptable': '可接受风险',
	'Immediate action for improvement and reassess': '立即采取行动进行改进并重新评估',
	'Improvement action': '改善措施',
	'Risk Very High': '风险极高',
	'Perform Ergonomic Work Analysis': '进行人体工程学工作分析',
	'Risk Serious and Imminent': '风险严重且迫在眉睫',
	'Stop the activity and carry out an Ergonomic Work Analysis': '停止活动并进行人体工程学工作分析',
	'Possible injuries': '可能受伤',
	'Fatigue complaints': '疲劳投诉',
	Absenteeism: '旷工',
	'Musculoskeletal complaints': '肌肉骨骼疾病',
	'Complaints of mental overload': '精神负担过重',
	'Complaints of irritability': '员工抱怨在工作时让人烦躁不安',
	'Nonconforming products': '不合格产品',
	'Low back pain complaints': '腰痛投诉',
	'Elbow pain': '肘部疼痛',
	'Shoulder pain': '肩部疼痛',
	Headache: '头痛',
	'Unexcused absences': '无故缺席',
	'Neck pain': '颈部疼痛',
	'Wrist pain': '腕部疼痛',
	Consequence: '结果',
	'Severe with fatalities and serious injuries': '严重，造成人员死亡和重伤',
	'Temporary disability': '暂时丧失工作能力',
	'Injuries with leave': '带薪休假受伤',
	'Severe with possibility of death or disability': '严重者可能死亡或致残',
	'Superficial injuries': '表皮损伤',
	'Catastrophic with numerous deaths': '灾难性事件，造成大量人员死亡',
	Probability: '可能性',
	'Most likely and expected outcome': '最可能和预期的结果',
	'Extremely remote possibility': '可能性极小',
	'Practically impossible, never happened': '几乎不可能，从未发生过',
	'Unlikely, but it has happened': '不太可能，但确实发生过',
	'Possible outcome': '可能的结果',
	'Rare but possible': '罕见但可能',
	'Remotely (never occurred)': '远程（从未发生过）',
	'Rarely (occurs in short bursts without definition)': '很少（短暂爆发，无定义）',
	'Irregular (once a month to once a year)': '不定期（每月一次至每年一次）',
	'Occasionally (once a week to once a month)': '偶尔（每周一次至每月一次）',
	'Frequent (at least once a day)': '频繁（每天至少一次）',
	'Continues (several times a day)': '持续（每天数次）',
	'Remotely (>90% green)': '远程（>90% 绿色）',
	'Rarely (80 to 89% green)': '很少（80% 至 89% 为绿色）',
	'Irregular (50 to 79% green)': '不定期的（50% 至 79% 绿色）',
	'Occasionally (30 to 49% green)': '偶尔（30% 至 49% 绿色）',
	'Frequent (10 to 29% green)': '频繁（10% 至 29% 绿色）',
	'Continues (<9% green)': '继续（<9% 绿色）',
	'Select the injury': '选择受伤情况',
	'Select the injuries': '选择受伤情况',
	'Select the consequence': '选择后果',
	'Select the probability': '选择概率',
	'Select the exposure': '选择暴露时间',
	"Some required steps wasn't filled": '一些必需的步骤没有完成',
	"Some required step wasn't filled": '一些必需的步骤没有完成',
	Score: '分数',
	Informations: '信息',
	'Select the sector': '选择行业',
	Analyst: '分析师',
	'Insert the analyst': '插入分析师',
	'Insert the responsibility': '插入责任',
	'Insert the cell': '插入单元格',
	'Insert the activity': '插入活动',
	'Insert the workstation': '插入工作站',
	'Enter the collection date': '输入取货日期',
	'Work Conditions': '工作环境',
	'Select the constraints perceived in the evaluated work condition': '选择评估工作条件中感知到的约束',
	'Work schedule': '工作日程',
	'Summary description of the place': '该地点的简要描述',
	'Simplified description of the expected task': '预期任务的简化描述',
	'Simplified description of the task performed': '所执行任务的简单描述',
	'Working population': '劳动人口',
	'Working population percentages must sum up to 100%.': '劳动人口百分比总和必须等于 100%。',
	"Worker's verbalization": '工人的言语',
	'Self-evaluation': '自我评估',
	'What is the level of stress perceived by the worker when performing the task?':
		'工人在执行任务时感受到的压力水平如何？',
	'Nothing stressful': '没什么压力',
	'Little stressful': '有点压力',
	'Working population must be greater than 0': '劳动人口必须大于 0',
	'Total working population must be greater than 0': '总劳动人口必须大于 0',
	workers: '工人',
	Stressful: '有压力',
	'Update the tool': '更新工具',
	'Tool already set': '工具已设置',
	'Tool not set yet': '工具尚未设置',
	'Very stressful': '非常紧张',
	'Extremely stressful': '压力极大',
	'Workday and concentration at work': '工作日和工作注意力',
	'Working hours of more than 8 hours a day': '每天工作时间超过8小时',
	'Existence of long and frequent overtime hours of work': '存在长时间、频繁加班的情况',
	'Stays long time continuous operation': '保持长时间连续运行',
	'Insufficient rest interval': '休息间隔不足',
	'Insufficient rest break': '休息不足',
	'Insufficient rest days': '休息日不足',
	'Unbalanced concentrations of work in a day, week, month or year': '一天、一周、一个月或一年内工作注意力不平衡',
	'Unbalanced concentrations of work among workers': '工人的工作集中度不均衡',
	'Insufficient rest between shifts (less than 11 hours)': '班次间休息不足（少于11小时）',
	'Remains long time continuous operation': '保持长时间连续运行',
	'Kind of work': '工作种类',
	'Lift and/or carry heavy objects': '举起和/或搬运重物',
	'Apply the tool': '应用工具',
	'Load surveys': '负荷调查',
	'Lifts and/or carries objects farther than 60 cm': '举起或携带距离超过 60 厘米的物体',
	'Performs tasks that require great strength': '执行需要很大力量的任务',
	'Performs tasks that require pronounced pushing and pulling forces': '执行需要明显推力和拉力的任务',
	'Performs repetitive work': '执行重复性工作',
	'Performs work that requires frequent finger, hand, or arm movements': '从事需要频繁移动手指、手或手臂的工作',
	'Do intensive work with a keyboard or other input devices': '使用键盘或其他输入设备进行密集工作',
	'Perform precision work': '执行精密工作',
	'Performs activities with high visual requirements': '执行对视觉要求较高的活动',
	'Postures and movements': '姿势和动作',
	'Stays in uncomfortable postures and movements': '保持不舒服的姿势和动作',
	'Performs continuous and/or highly frequent change in joint positions': '持续和/或频繁地改变关节位置',
	'Stays in constrained posture for long duration': '长时间保持受限姿势',
	'Walk for long duration and/or long distance (on a horizontal surface as well as an inclined surface)':
		'长时间和/或长距离行走（在水平表面和倾斜表面上）',
	'Performs frequent stair climbing': '经常爬楼梯',
	'Remains in a sedentary/standing position for a long time': '长时间保持静坐或站立姿势',
	'Postures requirements (Kinebot)': '姿势要求（Kinebot）',
	'Influence of workspace and task factors': '工作空间和任务影响因素',
	'Workspace is inadequate and forces an uncomfortable posture or limited movement':
		'工作空间不足，导致姿势不舒服或活动受限',
	'Workstation layout requires excessive movement or uncomfortable postures':
		'工作站布局需要过多的运动或不舒服的姿势',
	'The heights, distances and dimensions of controls or work surfaces are inadequate':
		'控制装置或工作表面的高度、距离和尺寸不够',
	'Anthropometry Table x Furniture (cm)': '体格测量表 x 家具 (厘米)',
	'Add information': '添加信息',
	'Found (cm)': '找到 (厘米)',
	'Foreseen (cm)': '预见（厘米）',
	'Enter a location': '输入位置',
	'Enter what was found': '输入找到的内容',
	'Insert predicted': '插入预测',
	'Performs handling of work objects above the shoulder or below the knee': '处理肩膀以上或膝盖以下的工作物体',
	'The workspace forces the worker to maintain the same working posture': '工作空间迫使工人保持相同的工作姿势',
	'Handles work objects that are difficult to handle and slippery': '处理难以拿起且打滑的工作物体',
	'Activity requires high-voltage contact or local pressure acting on the body':
		'活动需要高压接触或局部压力作用于身体',
	'Influence of psychosocial factors': '社会心理影响的因素',
	'Activity with mental overload or underload': '精神负荷过重或不足的活动',
	'Work pace with time pressure and high demands': '工作节奏快，时间压力大，要求高',
	'Presence of work-related stress': '存在工作相关压力',
	'Reports of low job satisfaction': '工作满意度低的报告',
	'Activity with lack of autonomy (low influence, low control)': '缺乏自主性的活动（影响力低、控制力低）',
	'Low social support': '社会支持度低',
	'Influence of environmental factors': '环境因素的影响',
	'Place with slippery and/or uneven floor': '地面湿滑或不平的地方',
	'There is exposure to whole body vibration (check ISO 2631)': '全身暴露于振动（参见 ISO 2631）',
	'Makes use of hand-arm vibration tools during work (check ISO 5349)': '工作时使用手臂振动工具；（参见 ISO 5349）',
	'There is exposure to whole body vibration (see ISO 2631)': '全身暴露于振动（参见 ISO 2631）',
	'Makes use of hand-arm vibration tools during work (see ISO 5349)': '工作时使用手臂振动工具；（参见 ISO 5349）',
	'Performs work using vehicles that transmit vibration throughout the body': '使用将振动传递至全身的车辆进行工作',
	'Hot or cold working environment': '炎热或寒冷的工作环境',
	'Location with background noise': '有背景噪音的位置',
	'Influence of organizational factors': '组织因素的影响',
	'Production standards are not clear or understood by workers': '生产标准不明确或工人不理解',
	'The operative mode descriptions are insufficient or wrong': '操作模式描述不充分或错误',
	'The time to carry out the activities is inadequate': '开展活动的时间不足',
	'The pace of work is accelerated': '工作节奏加快',
	'The content of the tasks is different from the technical means available': '任务内容与可用的技术手段不同',
	'Task with high cognitive demand': '认知需求较高的任务',
	'Anthropometry Table': '体格测量表',
	'Consolidate analysis': '合并分析',
	Deconsolidate: '拆分',
	'Successfully consolidated analysis': '成功整合分析',
	'Successfully deconsolidated analysis': '成功拆分分析',
	'Failed to generate the report': '无法生成报告',
	'Failed to generate PDF': '无法生成 PDF',
	'Cannot change a consolidated analysis': '无法更改合并分析',
	'Comments successfully saved': '备注已成功保存',
	'Action plan deleted successfully': '行动计划删除成功',
	'Task created successfully': '任务创建成功',
	'Task deleted successfully': '任务删除成功',
	'Task updated successfully': '任务更新成功',
	'Ops... something happened!': '哎呀...出事了！',
	'It was not possible to save your information': '无法保存您的信息',
	Hazardous: '危险',
	'Movement by score (Kinebot/REBA)': '根据分数移动（Kinebot/REBA）',
	'Cargo Handling (NIOSH)': '货物装卸（NIOSH）',
	'Manual Handling (KIM)': '手动搬运 (KIM)',
	'Push and Pull (KIM)': '推拉 (KIM)',
	'No more action plans available': '没有更多可用的行动计划',
	'No more workstations available': '没有更多可用的工作站',
	'No task available': '没有可用的任务',
	day: '天',
	days: '天',
	Location: '地点',
	Found: '成立',
	Foreseen: '预测',
	Condition: '状况',
	'NOT OK': '不好',
	"Score's sum": '总分',
	'Trunk posture from Kinebot': 'Kinebot 的躯干姿势',
	'Trunk posture from kinebot': 'kinebot 的躯干姿势',
	'Neck posture from Kinebot': 'Kinebot 的颈部姿势',
	'Neck posture from kinebot': 'kinebot 的颈部姿势',
	'Left elbow posture from Kinebot': 'Kinebot 左肘姿势',
	'Left elbow posture from kinebot': 'Kinebot 左肘姿势',
	'Right elbow posture from Kinebot': 'Kinebot 的右肘姿势',
	'Right elbow posture from kinebot': 'Kinebot 的右肘姿势',
	'Left shoulder posture from Kinebot': 'Kinebot 的左肩姿势',
	'Left shoulder posture from kinebot': 'Kinebot 左肩姿势',
	'Right shoulder posture from Kinebot': 'Kinebot 的右肩姿势',
	'Right shoulder posture from kinebot': 'Kinebot 的右肩姿势',
	'Failed to upload the file': '上传文件失败',
	'Two-factor authentication not found': '未找到双重身份验证',
	'Already created two-factor authentication': '已创建双重身份验证',
	'Invalid token': '令牌无效',
	'Token already validated': '令牌已经验证',
	'Token not yet validated': '令牌尚未验证',
	'Token expired': '令牌已过期',
	'Too early to resend email': '重新发送电子邮件为时过早',
	'Something went wrong checking token': '检查令牌时出错',
	'Authentication type same as default': '身份验证类型与默认相同',
	'Authentication type through app already enabled': '通过应用程序的身份验证类型已启用',
	'Two-factor authentication not enabled': '未启用双重身份验证',
	'Too many tries!': '尝试次数太多！',
	'Too many calls! Wait a moment to try again.': '呼叫次数过多！请稍候重试。',
	'Two-factor authentication generated successfully': '双重身份验证已成功生成',
	'Two-factor authentication validated successfully': '双重身份验证已成功验证',
	'Two-factor authentication method changed successfully': '双重身份验证方法更改成功',
	'Token sent successfully': '令牌发送成功',
	'Token resent successfully': '令牌重新发送成功',
	'Authenticator app enabled successfully': 'Authenticator 应用已成功启用',
	'Two-factor authentication deleted successfully': '已成功删除双重身份验证',
	'Two-factor authentication reset successfully': '双重身份验证重置成功',
	'Forced two-factor authentication enabled successfully': '强制双重身份验证已成功启用',
	'Forced two-factor authentication disabled successfully': '强制双重身份验证已成功禁用',
	'Account overview': '账户信息',
	Profile: '简介',
	'Street name': '街道名称',
	'Available time': '可用时间',
	'Time used': '所用时间',
	Activate: '启用',
	Deactivate: '停用',
	'Force two-factor authentication to all users': '强制所有用户进行双重身份验证',
	'When activated, all users will be forced to activate two-factor authentication on login.':
		'一旦激活，所有用户都将被强制在登录时激活双重身份验证。',
	'Two-factor authentication': '双重身份验证',
	'When activated, you will have to type a validation code every time you login.':
		'激活后，您每次登录时都必须输入验证码。',
	'You can choose to receive your code by email or connect your authentication app with a QRCode.':
		'您可以选择通过电子邮件接收代码或通过二维码连接您的身份验证应用程序。',
	'Reset 2FA': '重置 2FA',
	'Deactivate 2FA': '停用 2FA',
	'Confirm your password to proceed with the desired action': '确认您的密码以继续所需操作',
	'Deactivate two-factor authentication': '停用双重身份验证',
	'You are about to reset your authentication method.': '您即将重置您的身份验证方法。',
	'Select a new desired authentication type and insert one of your recovery token to perform this operation.':
		'选择新的所需身份验证类型并插入一个恢复令牌来执行此操作。',
	'Authentication method': '身份验证方法',
	'Recovery token': '恢复令牌',
	'Authenticator app': '身份验证器应用',
	'You are about to deactivate your 2FA.': '您即将停用 2FA。',
	'Confirming this action will delete your two-factor authentication. Are you sure?':
		'确认此操作将删除您的双重身份验证。您确定吗？',
	'Heads up! Closing now will delete the token you generated. Want to continue?':
		'注意！现在关闭将删除您生成的令牌。要继续吗？',
	'Enable two-factor authentication': '启用双重身份验证',
	'Select a method to generate your two-factor authentication': '选择生成双重身份验证的方法',
	'With this method, you will scan a QRCode so you can use your favorite authenticator app (such as Google Authenticator or Microsoft Authenticator). Then, all you have to do is type the code shown in your app when you login.':
		'使用此方法，您将扫描二维码，以便使用您最喜欢的身份验证器应用程序（例如 Google Authenticator 或 Microsoft Authenticator）。然后，您所要做的就是在登录时输入应用程序中显示的代码。',
	'With this method, you will receive the login code in your registered email. At the time of login, you must fill in the indicated field with the code sent to your email.':
		'通过此方法，您将在注册的电子邮件中收到登录代码。登录时，您必须将发送到您电子邮件中的代码填写到指定字段中。',
	'You are about to generate your 2FA with your authenticator app.': '您即将使用身份验证器应用程序生成 2FA。',
	'Be sure to have your mobile phone with you ready to scan your code.': '请务必随身携带手机，以便随时扫描代码。',
	'Validation code': '验证码',
	'Validation token': '验证令牌',
	'Enter the 6 digit code.': '输入 6 位数代码。',
	'Recovery tokens': '恢复令牌',
	'For security reasons, we need you to do your two-factor validation': '出于安全原因，我们需要您进行双重验证',
	'You are about to generate your two-factor authentication via the app method. Make sure you are close to your cell phone, with the authentication app open.':
		'您即将通过应用程序方法生成双重身份验证。请确保您的手机就在附近，并且身份验证应用程序已打开。',
	'You are about to generate your two-factor authentication via email method. Make sure you have your inbox open.':
		'您即将通过电子邮件方式生成双重身份验证。请确保您的收件箱已打开。',
	'If you have not received your code via email, you can try again by clicking':
		'如果您没有通过电子邮件收到代码，您可以点击重试',
	here: '这里',
	'Unable to generate token': '无法生成令牌',
	"Here are your recovery codes, which will be requested should you need to recover your account. Keep in mind that they will not be accessible again after this moment, so it's crucial that you save them securely.":
		'这是您的恢复代码，如果您需要恢复您的帐户，将会要求您提供这些代码。请记住，此后它们将无法再访问，因此请务必安全地保存它们。',
	"Can't close yet. Read the instructions.": '尚无法关闭。请阅读说明。',
	"Your company has asked for all users to keep two-factor authentication enabled for security reasons. You'll be asked to activate it again on your next login. Want to deactivate it anyway?":
		'出于安全原因，贵公司要求所有用户启用双重身份验证。下次登录时，您将被要求再次激活该功能。是否仍要停用它？',
	Change: '改变',
	Security: '安全',
	'Account security settings': '账户安全设置',
	'Account information summary': '帐户信息摘要',
	'Password must be at least 8 characters long': '密码长度必须至少为 8 个字符',
	'The password must have a maximum of 20 characters': '密码最多包含 20 个字符',
	'The password field is required': '密码字段为必填项',
	'Password field cannot be empty': '密码字段不能为空',
	'The password must contain capital letters': '密码必须包含大写字母',
	'The password must contain lowercase letters': '密码必须包含小写字母',
	'Password must contain numbers': '密码必须包含数字',
	'The password must contain at least one special character': '密码必须至少包含一个特殊字符',
	'Manage industrial site': '管理工业场地',
	'Update company name': '更新公司名称',
	'Delete company': '删除公司名称',
	'Total Industrial Sites': '工业用地总数',
	'Create workstation': '创建工作站',
	'Workstation name': '工作站名称',
	'Create sector': '创建部门',
	'Sector name': '部门名称',
	'Edit industrial site': '编辑工业用地',
	'All data will be deleted, along with their respective uploads, reports and contents. Do you wish to proceed?':
		'所有数据以及其各自的上传、报告和内容都将被删除。是否要继续？',
	'All companies in this organization will be deleted, along with their respective uploads and reports. Do you wish to proceed?':
		'此组织中的所有公司及其各自的上传和报告都将被删除。是否要继续？',
	'Edit sector': '編輯区',
	'Select a workstation': '选择工作站',
	'Please, select an industrial site': '请选择一个工业地点',
	'Please, select a sector': '请选择一个行业',
	'Please, select a workstation': '请选择一个工作站',
	'Edit workstation': '编辑工作站',
	'You will create a new workstation with same name. Do you wish to proceed ?':
		'您将创建一个同名的新工作站。是否继续？',
	'You will create a new company with same name. Do you wish to proceed ?': '您将创建一家同名的新公司。是否继续？',
	'You will create a new sector with same name. Do you wish to proceed ?': '您将创建一个同名的新编辑区。是否继续？',
	'This name already exists. Do you wish to proceed ?': '此名称已存在。是否继续？',
	'Create new company': '创建新公司名称',
	'You will create a new organization with same name. Do you wish to proceed ?':
		'您将创建一个同名的新组织。是否继续？',
	'Failed to update the report': '无法更新报告',
	'Failed to create the report': '无法创建报告',
	'Analyzed person': '分析者',
	Person: '人员',
	Persons: '人员',
	'Identify people to be analyzed': '确定要分析的人员',
	'No person has been identified with more than 50% of accuracy.': '尚未有任何人的身份识别准确率超过 50%。',
	'Please, record the video following the guidelines': '请按照指南录制视频',
	Files: '文件',
	'Select a desired new method and enter one of the recovery tokens to perform the recovery':
		'选择所需的新方法并输入其中一个恢复令牌来执行恢复',
	'For security reasons, we need you to enable your two-factor validation': '出于安全原因，我们需要您启用双重验证',
	'Your company is requesting that all users have two-factor authentication enabled. Proceed to activate yours and login.':
		'贵公司要求所有用户启用双重身份验证。继续激活您的双重身份验证并登录。',
	Return: '返回',
	'Continue with Microsoft SSO': '继续使用 Microsoft SSO',
	'General informations': '一般信息',
	'Material Handling': '物料搬运',
	'Material handling': '物料搬运',
	'Invalid request data': '请求数据无效',
	'Liberty Mutual report created successfully!': 'Liberty Mutual 报告创建成功！',
	'Liberty Mutual report updated successfully!': 'Liberty Mutual 报告更新成功！',
	'Liberty Mutual task not found': '未找到 Liberty Mutual 任务',
	'System of units not found': '未找到单元系统',
	Lift: '举起',
	Lifts: '升降机',
	lift: '举起',
	lifts: '升降机',
	Lower: '降低',
	Lowers: '降低',
	lowers: '降低',
	lower: '降低',
	Push: '推',
	Pushes: '推动',
	pushes: '推动',
	push: '推',
	Pull: '拉',
	Pulls: '拉力',
	pulls: '拉',
	pull: '拉',
	Carry: '携带',
	Carries: '携带',
	carries: '携带',
	carry: '携带',
	'System of unit': '单元系统',
	'Select the system of unit': '选择单元系统',
	'Select the movement of the task being evaluated': '选择正在评估的任务的移动',
	'Insert the fields data for the selected task': '插入选定任务的字段数据',
	'Start hand height': '起始手高度',
	'End hand height': '末端手高度',
	'Start hand distance': '起手距离',
	'End hand distance': '末端手距离',
	'Initial force': '初始力量',
	'Sustained force': '持续力量',
	'Horizontal distance': '水平距离',
	'Vertical hand height': '垂直手高度',
	'Hand coupling': '手动耦合',
	'Object weight': '物体重量',
	Weight: '重量',
	'Select a hand coupling': '选择手动耦合器',
	'Insert a start height': '插入起始高度',
	'Insert an end height': '插入结束高度',
	'Insert a start distance': '插入起始距离',
	'Insert an end distance': '插入末端距离',
	'Insert an object weight': '插入物体重量',
	'Insert a frequency value': '插入频率值',
	'Select a frequency interval': '选择频率间隔',
	'Insert an initial force': '插入初始力',
	'Insert a sustained force': '插入持续的力量',
	Initial: '最初的',
	Sustain: '维持',
	'Insert a horizontal distance': '插入水平距离',
	'Insert a vertical hand height': '插入垂直手高度',
	'Select a task': '选择任务',
	'per hour': '每小时',
	hour: '小时',
	'per minute': '每分钟',
	per: '每',
	'Video duration': '视频时长',
	'Assessment date': '评估日期',
	'Population percentile': '人口百分比',
	'Activity is safe for': '活动安全',
	'Initial state': '初始状态',
	'End state': '结束状态',
	Task: '任务',
	Metric: '公制',
	Imperial: '英制',
	'Data entered for Start hand height and End hand height indicates that the task type is Lift, not Lower':
		'输入的起始手高度和结束手高度的数据表明任务类型是提升，而不是降低',
	'Data entered for Start hand height and End hand height indicates that the task type is Lower, not Lift':
		'输入的起始手高度和结束手高度的数据表明任务类型是降低，而不是提升',
	'Built-in user groups': '内置用户组',
	'Connect your Microsoft Azure AD users': '连接 Microsoft Azure AD 用户',
	'Invitation sent': '邀请已发送',
	'Microsoft Azure Active Directory - Users': 'Microsoft Azure Active Directory - 用户',
	'Failed to load user groups': '无法加载用户组',
	'User removed successfully!': '用户删除成功！',
	'Failed to remove user': '无法移除用户',
	'Do you want to remove the user': '是否要删除该用户',
	'Failed to update user': '无法更新用户',
	'Failed to load information': '加载信息失败',
	'Investigate and implement change': '调查并实施变更',
	'Implement changes': '实施变化',
	Attention: '注意',
	'Upload logos': '上传徽标',
	'Use the same of the company': '使用与公司相同的',
	'.jpeg or .png up to 5MB': '.jpeg 或 .png 最大 5MB',
	'Upload files': '上传文件',
	'Upload your company logo': '上传您的公司徽标',
	'Upload your industrial site logo': '上传您的工厂标志',
	'Add a image': '添加图片',
	'Image successfully added': '图片添加成功',
	'Image successfully changed': '图片修改成功',
	'Uploaded picture': '已上传图片',
	'Changed picture': '更改了图片',
	'Edit picture': '编辑图片',
	Reset: '重置',
	'Track the minutes used on the platform during the desired period': '追踪所需时间段内平台使用的分钟数',
	'See more details': '查看更多详细信息',
	'Please select one or more': '请选择一个或多个',
	'The end date must be later than the start date': '结束日期必须晚于开始日期',
	Granularity: '精细度',
	'Select a granularity': '选择粒度',
	'Select a date': '选择日期',
	'Minutes used': '使用时间',
	Start: '开始',
	End: '结束',
	Users: '用户',
	'Failed to search for companies': '无法搜索公司',
	'Error in searching for user information': '搜索用户信息时出错',
	'To Do': '去做',
	Doing: '正在做',
	Done: '完毕',
	Status: '状态已改变',
	'Remember me': '记住账号',
	'Add item': '新增项目',
	'Type of force exertion in the finger/hand (left | right)': '手指/手部用力类型（左 | 右）',
	Password: '密码',
	Descending: '降序',
	Integrations: '集成',
	'Order by worst score': '按最差成绩排序',
	'Order by deadline': '按截止时间订购',
	'Select EWA': '选择 EWA',
	'Workstation score': '工作站分数',
	Responsibility: '责任',
	'average (seconds holding / minute)': '平均（保持秒数/分钟）',
	'average (movements / minute)': '平均（动作/分钟）',
	'Personalised reports': '个性化报告',
	'Personalised report': '个性化报告',
	'Created by': '由...制作',
	'Edit report': '编辑报告',
	'Created date': '创建日期',
	'New report': '新报告',
	'Manage report': '管理报告',
	Forward: '向前',
	'Require video': '需要视频',
	Evaluator: '评估者',
	'Require evaluator': '需要评估者',
	'Evaluator created successfully': '评估器创建成功',
	'Select an evaluator': '选择评估者',
	'Require work condition': '需要工作条件',
	'Require characteristics': '要求特征',
	Acronym: '缩写',
	'Insert an acronym': '插入缩写',
	'Risk scale': '风险量表',
	'Insert a risk scale': '插入风险量表',
	'Insert a report name': '插入报告名称',
	'Recommended sections': '推荐栏目',
	'Create section': '创建部分',
	'Section title': '章节标题',
	'Search by section name...': '按部分名称搜索...',
	'No sections selected': '未选择任何部分',
	'No more sections available': '没有更多可用部分',
	'No sections available': '没有可用的部分',
	'Section created successfully': '版块创建成功',
	'Section has already been added': '部分已添加',
	'Already created section': '已创建部分',
	'Selected sections': '选定部分',
	'Recommended criteria': '推荐标准',
	'Create criterion': '创建标准',
	'Criterion title': '标准标题',
	'Search by criterion name...': '按条件名称搜索...',
	'No criteria selected': '未选择任何条件',
	'No more criteria available': '没有更多可用条件',
	'No criteria available': '没有可用的标准',
	'Criterion created successfully': '标准创建成功',
	'Criterion has already been added': '标准已添加',
	'Already created criterion': '已创建标准',
	'Selected criteria': '选定标准',
	'Section not found': '未找到章节',
	'Failed to create section': '无法创建部分',
	'Failed to update section': '更新部分失败',
	'Criterion not found': '未找到标准',
	'Failed to create criterion': '无法创建标准',
	'Failed to update criterion': '更新标准失败',
	'Indicate possible injuries/aggravations': '指出可能出现的伤害/恶化情况',
	'Link tool to analysis': '将工具链接到分析',
	'Sections and criteria': '章节和标准',
	'No tool selected': '未选择任何工具',
	'Selected tools': '选定的工具',
	'Do not indicate possible injuries/aggravations': '不要表示可能造成的伤害/恶化',
	'Incomplete section': '不完整部分',
	'Custom report created successfully': '自定义报告创建成功',
	'Custom report updated successfully': '自定义报告更新成功',
	'Report downloaded': '报告已下载',
	'Analysis downloaded successfully': '分析下载成功',
	'Want to delete this criterion?': '想要删除这个标准吗？',
	'Want to delete this criterion? Deleting it will remove the section as well!':
		'想要删除此标准吗？删除它将同时删除该部分！',
	"Kinebot's standard prioritisation matrix": 'Kinebot 的标准优先级矩阵',
	'3 level prioritisation matrix': '级优先级矩阵',
	'6 level prioritisation matrix': '级优先级矩阵',
	'Select the restrictions perceived in the evaluated characteristics': '选择在评估特征中感知到的限制',
	'Particularities / Production / Working regime': '特性 / 生产 / 工作制度',
	'Select the restrictions perceived in the evaluated working condition': '选择在评估的工作条件中感知到的限制',
	'Insert work schedule': '插入工作日程表',
	'Work hours must be greater than 1': '工作时间必须大于 1',
	'Unauthorized requisition': '未经授权的请求',
	'Authorization header is missing': '缺少授权标头',
	'Failed to query the database': '查询数据库失败',
	'Calculation per second not found': '未找到每秒计算',
	'Business information not found': '未找到商家信息',
	'Custom report not found': '未找到自定义报告',
	'Already created custom report': '已创建自定义报告',
	'Failed to create custom report': '无法创建自定义报告',
	'Custom report result not found': '未找到自定义报告结果',
	'Already created custom report result': '已创建自定义报告结果',
	'Failed to create custom report result': '无法创建自定义报告结果',
	'Work condition not found': '未找到工作条件',
	'Already created work condition': '已经创建工作条件',
	'Failed to create work condition': '无法创建工作条件',
	'Characteristic not found': '未找到特征',
	'Already created characteristic': '已创建特征',
	'Failed to create characteristic': '无法创建特征',
	'Criteria result not found': '未找到条件结果',
	'Already created criteria result': '已创建条件结果',
	'Failed to create criteria result': '无法创建条件结果',
	'Failed to update criteria result': '无法更新条件结果',
	'Criteria injury not found': '未发现伤害标准',
	'Already created criteria injury': '已创建的伤害标准',
	'Failed to create criteria injury': '未创建的伤害标准',
	'Custom report score scale not found': '未找到自定义报告评分标准',
	'Worker self evaluation not found': '未找到工人自我评估',
	'Custom report setting not found': '未找到自定义报告设置',
	'Custom report step setting not found': '未找到自定义报告步骤设置',
	'Criteria settings not found': '未找到条件设置',
	'Step key ergonomic tool not found': '未找到 步骤关键 人体工学工具',
	'Failed to create step key ergonomic tool': '无法创建步骤关键人体工程学工具',
	'Failed to update step key ergonomic tool': '无法更新步骤关键人体工学工具',
	'Evaluator not found': '未找到评估器',
	'Already created evaluator': '已创建评估器',
	'Ergonomic tool not found': '未找到人体工程学工具',
	'Ergonomic tool category not found': '未找到人体工程学工具类别',
	'Action plan not found': '未找到行动计划',
	'Failed to create an history': '无法创建历史记录',
	'Already created action plan': '已制定行动计划',
	'Action plan task not found': '未找到行动计划任务',
	'Action plan history not found': '未找到行动计划历史记录',
	'Preliminary analysis not found': '未找到初步分析',
	'Analysis step not found': '未找到分析步骤',
	'Default step not found': '未找到默认步骤',
	'Probabilty not found': '未找到概率',
	'Injury not found': '未发现伤势',
	'Consequence not found': '未找到结果',
	'Exposure not found': '未找到曝光',
	'Range risk not found': '未发现范围风险',
	'Failed to fetch risk tracks': '无法获取风险轨迹',
	'Organization not found': '未找到组织',
	'Organization still has registered companies': '该组织仍有注册公司',
	'Failed to create an organization': '无法创建组织',
	'Company not found': '未找到公司',
	'Failed to create company': '创建公司失败',
	'Sector not found': '未找到扇区',
	'Workstation not found': '未找到工作站',
	'Corrupted video': '视频损坏',
	'File not found': '文件未找到',
	'Parameters not found': '未找到参数',
	'Failed to read sent file': '无法读取已发送的文件',
	'Failed to save file': '保存文件失败',
	'Failed to create download url': '无法创建下载 URL',
	'File already queued': '文件已排队',
	'File already processed': '文件已处理',
	'Failed to save file information': '保存文件信息失败',
	'Already registered user': '已经注册用户',
	'Failed to create customer plan': '无法创建客户计划',
	'Failed to create customer transaction': '无法创建客户交易',
	'Customer transaction not found': '未找到客户交易',
	'Customer transaction already completed': '客户交易已完成',
	'Product not found': '未找到产品',
	'Failed to create customer credits': '无法创建客户信用',
	'Plan not found': '未找到计划',
	'Processed minutes limit reached': '已达到处理分钟数限制',
	'Invalid event': '无效事件',
	'Not authorized': '未经授权',
	'Failed to create a user permission': '无法创建用户权限',
	'Customer not found': '未找到客户',
	'Unregistered customer': '未註冊客戶',
	'Already registered customer': '已经注册客户',
	'Failed to create customer': '无法创建客户',
	'Failed to update customer': '无法更新客户',
	'Report not found': '未找到报告',
	'Already created report': '已创建报告',
	'Failed to create report': '无法创建报告',
	'Failed to create report pdf': '无法创建报告 pdf',
	'Failed to update report': '无法更新报告',
	'Group not found': '未找到群组',
	'User group not found': '未找到用户组',
	'Failed to upload file to storage': '无法将文件上传至存储',
	'Failed to create file signature': '无法创建文件签名',
	'Failed to download data': '下载数据失败',
	'Reply queue communication failure': '回复队列通信失败',
	'1% to 10% of the cycle': '周期的 1% 至 10%',
	'11% to 20% of the cycle': '周期的 11% 至 20%',
	'21% to 30% of the cycle': '周期的 21% 至 30%',
	'31% to 50% of the cycle': '周期的 31% 至 50%',
	'51% to 75% of the cycle': '周期的 51% 至 75%',
	'76% to 100% of the cycle': '周期的 76% 至 100%',
	'Physical or cognitive discomfort': '身体或认知不适',
	'Muscle fatigue or mental overload': '肌肉疲劳或精神负荷过重',
	'Acute pain': '急性疼痛',
	'Illness with partial loss of working capacity - without time off work': '患病导致部分丧失工作能力 - 无法休假',
	'Illness with total loss of working capacity - with time off work': '患病并完全丧失工作能力 - 需要休假',
	'Disability retirement': '残疾退休',
	'Illness - with leave': '患病-请假',
	'Up to 25% of the cycle': '高达周期的 25%',
	'26% to 75% of the cycle': '周期的 26% 至 75%',
	Unlikely: '不太可能',
	Remote: '偏僻的',
	Occasional: '偶然',
	Likely: '有可能',
	Frequent: '频繁',
	Certainly: '当然',
	'Create/update tool': '创建/更新工具',
	'Driveway conditions are not applied for the selected vehicle.': '车道条件不适用于所选车辆。',
	'Your organization uses single sign-on (SSO). Use your corporate email to access Kinebot.':
		'您的组织使用单点登录 (SSO)。使用您的公司电子邮件访问 Kinebot。',
	'Sign in without SSO': '不使用 SSO 登录',
	'Enter a valid email': '输入有效的电子邮件',
	'Enter your email': '输入你的电子邮箱',
	'Work email': '工作邮箱',
	'Oops! Something happened.': '哎呀！出状况了。',
	'Failed to fetch information': '无法获取信息',
	'Continue with SSO': '继续 SSO',
	'Return to login without SSO': '返回不使用 SSO 的登录',
	'You are being redirected': '您正在重定向',
	'User not associated with an SSO account': '用户未与 SSO 帐户关联',
	'Fail get user info': '获取用户信息失败',
	'The server encountered an unexpected situation.': '服务器遇到意外情况。',
	'Please enter a valid email address.': '请输入有效的电子邮件地址。',
	'Do you wish to delete this user?!': '您想刪除該使用者嗎？',
	'Insert a name': '插入姓名',
	'Insert an e-mail': '插入電子郵件',
	Find: '巴士車',
	'You are creating user': '您正在建立用戶',
	'is receiving permission level': '正在接收權限級別',
	'in the following companies and industrial sites:': '在以下公司和工業場所：',
	'Companies and Industrial Sites': '公司和工業場地',
	'is a new user with the role': '是具有該角色的新用戶',
	user: '使用者',
	USER: '使用者',
	'You are removing the permission level': '您正在刪除權限級別',
	'You are changing': '你正在改變',
	"permission's level to": '權限等級為',
	'Do you want to confirm the change?': '您想確認更改嗎？',
	'Search a company or industrial site': '搜索公司或工业场地',
	'Create user': '創建用戶',
	'is a new user': '是新用戶',
	'in the following companies and industrial sites': '在以下公司和工業場所',
	'with permission level': '具有權限級別',
	'Do you want do confirm the creation?': '您想確認創建嗎？',
	'User permissions updated successfully': '使用者權限更新成功',
	'User deleted successfully': '用戶刪除成功',
	'User already registered': '用戶已註冊',
	'Delete user': '刪除用戶',
	'You must change user role or at least one premission.': '您必須更改使用者角色或至少一項權限。',
	'Heads up! Want to disable this user?': '小心！想要停用該用戶嗎？',
	'Heads up! Want to enable this user?': '小心！想要啟用該用戶嗎？',
	'You need to make at least one change.': '您需要至少進行一項更改。',
	'Failed to fetch workstations': '無法取得工作站',
	Carriage: '運輸車',
	Barrow: '手推車',
	Conveyor: '輸送帶',
	Crane: '起重機',
	Edit: '編輯',
	'User status updated successfully': '使用者狀態更新成功',
	'Failed to update user status': '無法更新使用者狀態',
	Download: '下载',
	Total: '全部的',
	'Strain index': '應變指數',
	'Submission Failed': '提交失敗',
	'REBA - Rapid Entire Body Assessment': 'REBA - 快速全身評估',
	'Organizational influences': '組織影響',
	'The most critical variables are listed below with suggestions:': '以下列出了最關鍵的變數以及建議：',
	'Lifted by ': '舉起者',
	'Coupling factor: ': '耦合因子: ',
	'Frequency factor: ': '频率因子: ',
	'Comment:': '评论:',
	'Select the mass to be transported (in kg):': '选择要运输的质量（以千克为单位）:',
	'Driveway conditions:': '车道状况:',
	'Inclination or stairs:': '斜坡或楼梯:',
	'Select one of the vehicles or a wheel type:': '选择一种车辆或车轮类型:',
	'Conditions:': '狀況：',
	'None: there are no unfavorable working conditions': '無：不存在不利的工作條件',
	'None: there are no unfavorable properties of the transport devices': '無：傳輸設備沒有不利的特性',
	'Mass: ': '大量的:',
	'Vehicle: ': '车辆:',
	'Head posture: variable, head not inclined backward and/or severely inclined forward or constantly moving':
		'頭部姿勢：可變，頭部不向後傾斜和/或嚴重向前傾斜或不斷移動',
	'Occasional deviations from good “neutral” head posture/movement': '偶爾偏離良好的“中立”頭部姿勢/運動',
	'Frequent deviations from good “neutral” head posture/movement': '經常偏離良好的“中立”頭部姿勢/運動',
	'Constant deviations from good “neutral” head posture/movement': '持續偏離良好的「中立」頭部姿勢/運動',
	'Select project': '选择项目',
	'Barely noticeable or relaxed effort (BS: 0-2)': '几乎不明显或放松的努力（BS：0-2）',
	'Noticeable or definite effort (BS: 3)': '明显或明确的努力（BS：3）',
	'Obvious effort; Unchanged facial expression (BS: 4-5)': '明显的努力；面部表情不变（BS：4-5）',
	'Substantial effort; Changes expression (BS: 6-7)': '付出巨大的努力；改变表达（BS：6-7）',
	'Uses shoulder or trunk for force (BS: 8-10)': '使用肩膀或躯干发力（BS：8-10）',
	Local: '当地的',
	'Workspace is inadequate and forces an uncomfortable posture or unlimited movement':
		'工作空间不足，导致姿势不舒服或无限制移动',
	'by ': '经过 ',
	'Deadline : ': '最后期限 : ',
	'Score : ': '分数 : ',
	supervisor: '主管',
	master: '负责人',

	'Failed to remove file': '删除文件失败',
	'Preliminary Ergonomic Analysis': '初步人体工程学分析',
	'Select the risk range': '选择风险范围',
	'Enter the activity': '输入活动',
	'Select the evaluator': '选择评估员',

	// Dashboard
	Evaluated: '已评估',
	'Not evaluated': '未评估',
	'Highest risk found': '发现的最高风险',
	'Predetermined time frame': '预设时间范围',
	'Select the time frame': '选择时间范围',
	'Select the checklist': '选择检查清单',
	'Previous period': '上一时期',
	'The period will only be applied to cards that include these variables.': '该时期仅适用于包含这些变量的卡片。',
	'Reference checklist': '参考检查清单',
	'Reference period': '参考时期',
	'Comparison period': '比较时期',
	'Current month': '本月',
	'Current quarter': '本季度',
	'Current semester': '本学期',
	'Current year': '本年',
	'Previous month': '上个月',
	'Previous quarter': '上季度',
	'Previous semester': '上学期',
	'Previous year': '去年',
	'Comparison of highest risk found between': '最高风险之间的比较',
	'Industrial Sites': '工业场地',
	quarter: '季度',
	semester: '学期',
	Jan: '一月',
	Feb: '二月',
	Apr: '四月',
	May: '五月',
	Jun: '六月',
	Jul: '七月',
	Aug: '八月',
	Sep: '九月',
	Oct: '十月',
	Nov: '十一月',
	Dec: '十二月',
	D: '日',
	W: '周',
	M: '月',
	'3M': '季',
	'6M': '半年',
	Y: '年',
	'Generated reports': '生成的报告',
	'Number of reports': '报告数量',
	'Left side': '左侧',
	'Right side': '右侧',
	'Exposure by body side': '暴露部位',
	'Revised Strain Index ≤10': '修订后的应变指数 ≤10',
	'Revised Strain Index >10': '修订后的应变指数 >10',
	'Most frequent movement': '最频繁的运动',
	'Movement frequency': '运动频率',
	'Incidence of risk': '风险发生率',
	'Evolution of the highest risk found': '最高风险发现的变化',
	'Action plan status': '行动计划状态',
	'No data available.': '暂无数据。',
	'Number of actions': '操作数量',
	Late: '已延迟',
	'Not started': '未开始',
	'This page was not found.': '未找到此页面。',
	'Try Again': '重试',
	'Select the video files for the operations that make up the workstation below': '选择视频文件以组成工作站的操作',
	Goals: '目标',
	'Indicate the minimum acceptable exposure levels for the rotation': '指示最小可接受的暴露水平',
	'Minimum acceptable exposure level per body part': '最小可接受的暴露水平',
	'Cervical spine': '颈椎',
	'Evaluation of the rotation': '评估旋转',
	'Exposure of selected activities': '暴露选定活动',
	'Result of the cycle': '循环结果',
	Operation: '操作',
	'Operation time': '操作时间',
	Sequence: '序列',
	'Overall exposure': '总体暴露',
	Rotation: '旋转',
	'Pause Identification': '暂停识别',
	'Time (minutes)': '时间（分钟）',
	Break: '休息',
	'Provide a description': '提供描述',
	'Provide the time': '提供时间',
	'Select the operation': '选择操作',
	Required: '必填',
	'Heads up! Want to delete this operation?': '小心！想要删除这个操作吗？',
	'Activity updated successfully': '活动更新成功',
	'Activity deleted successfully': '活动删除成功',
	'Report does not have activities': '报告没有活动',
	'Overall results of the rotation': '总体结果',
	'Operations and videos': '操作和视频',
	'Total cycle time': '总循环时间',
	'Minimum acceptable exposure': '最小可接受的暴露',
	'Exposure found': '暴露发现',
	'Manual Tools': '手动工具',
	'List of work organization elements': '工作组织元素列表',
	'Work organization factor': '工作组织因素',
	'Tasks at the pace of the machine': '任务以机器的速度完成',
	'Incentives are charged': '激励被收取',
	'Exists as routine, overtime': '作为例行工作存在，加班',
	'Strict control of working times': '严格控制工作时间',
	'Limited decision-making possibilities': '有限的决策能力',
	'Monotonous work': '单调的工作',
	Shoulder: '肩膀',
	'Individually evaluate the upper extremities': '评估上肢',
	'Flexion/extension': '弯曲/伸展',
	'Angle in time': '时间角度',
	'Extreme posture': '极端姿势',
	Speed: '速度',
	'Total duration of the activity': '活动总持续时间',
	Strength: '力量',
	Abduction: '外展',
	Forearm: '前臂',
	'Pronation, supination and rotation': '旋前，旋后和旋转',
	Slow: '慢',
	Static: '静态',
	Fast: '快',
	'Supports part of the weight': '支持部分重量',
	'Apply force': '施加力量',
	"Don't apply force": '不施加力量',
	Partial: '部分',
	Neutral: '中性',
	Complete: '完整',
	'Partial + straight fingers': '部分 + 直指',
	'Partial + flexed fingers': '部分 + 弯曲指',
	Grips: '抓握',
	'Flexion/extension or radial/ulnar deviation': '弯曲/伸展或径向/尺侧偏差',
	'Tool pressure': '工具压力',
	Circular: '圆形',
	Pinch: '捏',
	Dynamic: '动态',
	Infrequent: '不频繁',
	'On palm or wrist': '在手掌或手腕上',
	Fingers: '手指',
	Keystrokes: '按键',
	'Two hands': '双手',
	'Flexion, extension, rotation': '弯曲, 伸展, 旋转',
	'Risk Condition': '风险条件',
	'Risk condition': '风险条件',
	'Identify problems associated with tool use and exposure to vibration.': '识别与工具使用和振动暴露相关的问题。',
	'Vibration (Tool is pneumatic)': '振动（工具是气动的）',
	'Uses tools that produce vibration': '使用产生振动的工具',
	'Total daily exposure': '总日暴露',
	'Exhaustion (Directly in hand)': '疲劳（直接在手中）',
	'Ambient temperature': '环境温度',
	'Measurement (m/s²)': '测量（m/s²）',
	'Vibration (Electric motor or combustion engine)': '振动（电动机或内燃机）',
	'Action Plan': '行动计划',
	'Action plans created': '行动计划已创建',
	'Task description': '任务描述',
	'Number of exhibits': '展品数量',
	'Classification of risks': '风险分类',
	'Grip type': '抓握类型',
	'Trigger action': '触发动作',
	'If the tool is electric motor or combustion engine': '如果工具是电动机或内燃机',
	'If the tool is pneumatic': '如果工具是气动的',
	'Part of the body': '身体的一部分',
	Movement: '运动',
	shoulder: '肩膀',
	wrist: '手腕',
	forearm: '前臂',
	grips: '抓握',
	fingers: '手指',
	vibration: '振动',
	'* ANSI Z-365 Control of Work-Related Cumulative Trauma Disorder, Part 1: Upper Extremities, Working Draft, National safety Council-Secretariat, January 1, 1996.':
		'* ANSI Z-365 控制与工作相关的累积性创伤障碍，第1部分：上肢，工作草案，国家安全委员会-秘书处，1996年1月1日。',
	'Select the': '选择',
	'Input the': '输入',
	'Not applicable': '不适用',
	Board: '木板',
	Table: '桌子',
	Notifications: '通知',
	Investment: '投资',
	Origin: '起源',
	'Action plan name': '行动计划标题',
	'Expected cost of the action plan': '行动计划成本预期',
	'Categorization of the urgency of action plan resolution': '行动计划决议的紧迫性分类',
	'Create an action plan': '制定行动计划',
	'Are you sure you want to delete the selected task?': '您确定要删除所选任务吗？',
	'Notification Control': '通知控制',
	'Follow up action plans created by me': '跟进我制定的行动计划',
	'Receive an e-mail when the status of the action plan changes': '当行动计划的状态发生变化时收到电子邮件',
	'Receive e-mail when tasks are completed': '任务完成后收到电子邮件',
	'Follow up on action plans assigned to me': '跟进分配给我的行动计划',
	'Receive an e-mail when a new action plan is assigned to me': '当分配给我新的行动计划时收到电子邮件',
	'Receive an e-mail when the due date is near': '当分配给我新的行动计划时收到电子邮件',
	'Creation date': '创建日期',
	'Due Date': '到期日',
	Evidences: '证据',
	'Date of Completion': '完成日期',
	Attachments: '附件',
	Progress: '进步',
	Author: '作者',
	'Manage Columns': '管理栏目',
	'Priority editing': '优先编辑',
	'Due date editing': '截止日期编辑',
	'Responsible editing': '负责任的编辑',
	'Are you sure you want to delete the selected items?': '您确定要删除所选项目吗？',
	'Investment value': '投资价值',
	'Failed to load attachments': '加载附件失败',
	'Failed to load data': '加载数据失败',
	'New task': '新任务',
	'This page was not found': '找不到该页面',
	'Try again': '再试一次',
	'New Task': '新任务',
	Sort: '种类',
	'Select the responsible': '选择负责人',
	'Alphabetical crescent': '按字母顺序排列的新月形',
	'Alphabetical descending': '按字母降序排列',
	'Most recent': '最近的',
	'Least recent': '最近的',
	'Nearest due date': '最近的预产期',
	'Furthest due date': '最远到期日',
	'Highest priority': '最高优先级',
	'Lowest priority': '最低优先级',
	'Highest investment': '最高投资',
	'Lowest investment': '最低投资',
	'Action plans updated successfully': '成功更新行动计划',
	'Selected action plans deleted successfully': '已成功删除行动计划',
	'File sent for download': '文件已发送供下载',
	Exporting: '出口',
	Unknown: '未知',
	'Enter the action plan name': '输入行动计划名称',
	'Description of the file': '文件描述',
	'Failed to deleted selected action plans': '无法删除选定的行动计划',
	'Failed to upload file': '上传文件失败',
	'File uploaded successfully': '文件上传成功',
	'Select the due date': '选择截止日期',
	'Delete action plan': '删除行动计划',
	'Are you sure you want to delete this action plan?': '您确定要删除此行动计划吗？',
	'Evidence removed': '证据已删除',
	'Attachment removed': '附件已删除',
	'Evidence updated successfully': '证据更新成功',
	'Attachment updated successfully': '附件更新成功',
	'Action plan attachment not found': '未找到行动计划附件',
	'Action plan evidence not found': '未找到行动计划证据',
	'Task removed successfully': '任务删除成功',
	'Action plan updated': '行动计划已更新',
	'Drag or select the files you want to attach to your action plan': '拖动或选择要附加到行动计划的文件',
	'Are you sure you want to remove the attachment': '您确定要删除此附件吗',
	'File description': '文件描述',
	'Upload author': '上传作者',
	'Attach files': '附加文件',
	'Attach evidences': '附加证据',
	Responsable: '负责人',
	'Alphabetical A-Z': '字母顺序 升序',
	'Alphabetical Z-A': '字母顺序 降序',
	'Lowest investiment': '最低投资',
	'Attach new file': '附加新文件',
	'Action plan deleted': '行动计划已删除',
	'Description changed': '描述已更改',
	'Title changed': '标题已更改',
	'Responsible changed': '负责人已更改',
	'Due date changed': '截止日期已更改',
	'Priority changed': '优先级已更改',
	'Investment changed': '投资已更改',
	'Attachment added': '附件已添加',
	'Evidence added': '证据已添加',
	'Task changed': '任务已更改',
	'Task removed': '任务已删除',
	'Task added': '任务已添加',
	'Task completed': '任务已完成',
	'Task uncompleted': '任务未完成',
	'Linked tool': '已链接工具',
	'Unlinked tool': '已取消链接工具',
	'Comment added': '评论已添加',
	'Comment removed': '评论已删除',
	'Comment changed': '评论已更改',
	'Error fetching responsible options': '获取负责人选项时出错',
	'Please select a priority': '请选择优先级',
	'Please select a date': '请选择日期',
	'Please select a responsible': '请选择负责人',
	By: '由',
	'EWA JDS-D86': 'AEP + JDS-D86',
	'Open action plan': '打开行动计划',
	'There are tasks that have not been saved.': '有尚未保存的任务',
	'Tools updated successfully': '工具更新成功',
	'Are you sure you want to delete this comment?': '您确定要删除此评论吗？',
	'Soon, you will be able to choose your own risk range': '很快，您可以选择自己的风险范围',
	'File activities must have unique names in same report': '文件活动必须具有相同的名称才能在同一报告中活动',
	'Multitasks and rotation': '多任务和旋转',
	'Multitasking and rotation assessment tool': '多任务和旋转评估工具',
	'All files': '所有文件',
	'Selected files': '选定文件',
	'Files added to report successfully': '文件成功添加到报告中',
	'Quantity of times that the activity repeats': '活动重复的次数',
	Goal: '目标',
	'REBA tool setup': 'REBA工具设置',
	Pause: '暂停',
	'Video files': '视频文件',
	'Do you really want to delete this action plan?': '您确定要删除此行动计划吗？',
	'Failed to generate the PDF': '生成PDF失败',
	'The ISO 11226 standard states that for static activities the minimum percentage should be 80% (ISO 11226:2013: Ergonomics - Assessment of static working postures)':
		'ISO 11226标准规定，对于静态活动，最小百分比应为80%（ISO 11226:2013：人体工程学 - 评估静态工作姿势）'
};

export default dictionary;
