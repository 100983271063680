import React from 'react';
import moment from 'moment';
import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { I18n } from '@aws-amplify/core';
import { Router } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from 'styled-components';

import 'moment/locale/pt';
import 'moment/locale/es';
import antdPtBr from 'antd/es/locale/pt_BR';
import antdEsEs from 'antd/es/locale/es_ES';
import antdDeDe from 'antd/es/locale/de_DE';
import antdZhCn from 'antd/es/locale/zh_CN';

import store from './store';
import Routes from './routes';
import dictionary from './i18n';
import history from './store/history';
import GlobalStyle from './styles/global';
import { queryClient } from '@/store/query';
import { lightTheme } from './styles/themes/light';
import { LocalesOptions, LocaleSelected } from './types';
import { ApplicationProvider } from './context/Application';
// import { Maintenance } from '@/pages/Maintenance';

const language: string = navigator.language;

moment.locale(language);
I18n.setLanguage(language);
I18n.putVocabularies(dictionary);

const antdLocales: LocalesOptions = {
	en: undefined,
	pt: antdPtBr,
	es: antdEsEs,
	de: antdDeDe,
	zh: antdZhCn
};

const antdLocale: LocaleSelected = antdLocales[language.substring(0, 2)] ?? antdLocales.en;

export const App = () => {
	// return <Maintenance />;

	return (
		<ThemeProvider theme={lightTheme}>
			<ConfigProvider locale={antdLocale}>
				<QueryClientProvider client={queryClient}>
					<Provider store={store}>
						<Router history={history}>
							<ApplicationProvider>
								<Routes />
							</ApplicationProvider>
						</Router>
					</Provider>
				</QueryClientProvider>
			</ConfigProvider>
			<GlobalStyle />
		</ThemeProvider>
	);
};
